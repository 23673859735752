import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { setLoading } from '@/main';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue')
  },
  {
    path: '/vue-3d',
    name: 'InteractiveFloorsView',
    component: () => import('../views/InteractiveFloorsView.vue'),
  },
  {
    path: '/vue-2d-dates',
    name: 'FlatFloorView',
    component: () => import('../views/FlatFloorView.vue')
  },
  {
    path: '/vue-2d-phases',
    name: 'FlatFloorPhasesView',
    component: () => import('../views/FlatFloorPhasesView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from) => {
  setLoading(true);
});

router.beforeResolve((to, from) => {
  setLoading(false);
});

export default router;
