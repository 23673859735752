import {
    geometryTypes
} from "./geometry-types";
import {
    IfcAPI, IFCRELDEFINESBYPROPERTIES
} from "web-ifc/web-ifc-api";

const table = document.createElement("table");

const ifcapi = new IfcAPI();

export async function LoadFileData(ifcAsText) {
    //Format data with IFCJs library functions
    const uint8array = new TextEncoder().encode(ifcAsText);
    const modelID = await OpenIfc(uint8array);
    console.log("MODEL ID:", modelID);
    const allItems = GetAllItems(modelID);
    console.log("ALL ITEMS:", allItems);

    //Retrieve clients
    const clients = GetClients(allItems);
    console.log("CLIENTS", clients);
    
    return clients;
}

export function GenerateVertices(verticesArray) {
    let vertices = [];
    for(const points of verticesArray) {
        vertices.push({
            x: points[0].value,
            y: points[1].value
        })
    }

    return vertices;
}

/**
 * 
 * @param {*} ifcData : all data from IFC file
 * @returns all areas (clients) from IFC file
 */
export function GetClients(ifcData) {
    const clientData = Object.fromEntries(Object.entries(ifcData).filter((obj) => obj[1].type == "IfcSpace" || obj[1].type == "SO"));
    console.log("CLIENT DATA:", clientData);
    const arrayClients = Object.keys(clientData)
        .map(function (key) {
            return clientData[key];
        });

    const clients = [];
    console.log("ARRAY CLIENTS:", arrayClients);
    for (const client of arrayClients) {
        const directions = GetDirections(ifcData,client);
        clients.push({
            clientId: client.GlobalId.value,
            clientZoneName: client.LongName.value,
            vertices: GenerateVertices(GetAreaFromClient(ifcData, client)),
            spaceOrigin: GetSpaceOrigin(ifcData, client),
            axis: directions[0],
            orientation: directions[1]

        });
    }

    return clients;
}

function GetAreaFromClient(ifcData, client) {
    const representation = ifcData[client.Representation.value];
    const productDefinitionShape = representation.Representations[1].value;
    const shapeRepresentation = ifcData[productDefinitionShape].Items[0].value;
    const geometricCurveSet = ifcData[shapeRepresentation].Elements[0].value;
    const indexedPolyCurve = ifcData[geometricCurveSet];
    
    const objIndexes = Object.values(indexedPolyCurve.Segments);
    objIndexes.splice(0,7);
    let arrayIndexes = objIndexes.map(index => index.value);
    arrayIndexes = [arrayIndexes[arrayIndexes.length-1], ...arrayIndexes];
    
    const cartesianPointList2D = ifcData[indexedPolyCurve.Points.value].CoordList;
    
    const finalCartesianPoint = [];
    for(const index of arrayIndexes) {
        finalCartesianPoint.push(cartesianPointList2D[index-1]);
    }
    return finalCartesianPoint;
}

/**
 * 
 * @param {*} ifcData : all data from IFC file
 * @param {*} client : specific area (hachures)
 * @returns the absolute origin of the area
 */
function GetSpaceOrigin(ifcData, client) {
    const objectPlacement = ifcData[client.ObjectPlacement.value];
    const relativePlacement = objectPlacement.RelativePlacement.value;
    const axisPlacement = ifcData[relativePlacement].Location.value;
    const cartesianPointObject = ifcData[axisPlacement].Coordinates;
    const cartesianPoints = {
        x: cartesianPointObject[0].value,
        y: cartesianPointObject[1].value,
        z: cartesianPointObject[2].value
    }

    return cartesianPoints;
}

function GetDirections(ifcData, client) {
    const objectPlacement = ifcData[client.ObjectPlacement.value];
    const relativePlacement = objectPlacement.RelativePlacement.value;
    const axisPlacement = ifcData[relativePlacement];
    const orientationD2 = ifcData[axisPlacement.RefDirection.value].DirectionRatios;
    const orientation = {
        x: orientationD2[0].value,
        y: orientationD2[1].value,
        z: orientationD2[2].value,
    }
    const axisD1 = ifcData[axisPlacement.Axis.value].DirectionRatios;
    const axis = {
        x: axisD1[0].value,
        y: axisD1[1].value,
        z: axisD1[2].value,
    }
    return [axis, orientation];
}

export function GetAreaItems(ifcData) {
    const areaData = Object.fromEntries(Object.entries(ifcData).filter((obj) => obj[1].type == "IfcCartesianPointList2D"));
    const arrayAreas = Object.keys(areaData)
        .map(function (key) {
            return areaData[key];
        });
    const areas = [];
    for(const area of arrayAreas) {
        areas.push({
            vertices: area.CoordList
        });
    }
    
    return areas;
}

export async function OpenIfc(ifcAsText) {
    await ifcapi.Init();
    console.log("IFC AS TEXT:", ifcAsText);
    return ifcapi.OpenModel(ifcAsText);
}

export function GetAllItems(modelID, excludeGeometry = false) {
    const allItems = {};
    const lines = ifcapi.GetAllLines(modelID);
    getAllItemsFromLines(modelID, lines, allItems, excludeGeometry);
    return allItems;
}

export function getAllItemsFromLines(modelID, lines, allItems, excludeGeometry) {
    for (let i = 1; i <= lines.size(); i++) {
        try {
            saveProperties(modelID, lines, allItems, excludeGeometry, i);
        } catch (e) {
            // console.log(e);
        }
    }
}

export function saveProperties(modelID, lines, allItems, excludeGeometry, index) {
    const itemID = lines.get(index);
    const props = ifcapi.GetLine(modelID, itemID);
    props.type = props.__proto__.constructor.name;
    if (!excludeGeometry || !geometryTypes.has(props.type)) {
        allItems[itemID] = props;
    }
}

export function getPropertyWithExpressId(modelID = 0) {
    const prop = document.getElementById("properties");
    prop.innerHTML = "";
    table.innerHTML = "";

    const elementID = parseInt(document.getElementById("expressIDLabel").value);

    // If third parameter is added as true, we get a flatten result
    const element = ifcapi.GetLine(modelID, elementID);

    // Now you can fetch GUID of that Element
    const guid = element.GlobalId.value;
    createRowInTable("GUID", guid);

    const name = element.Name.value;
    createRowInTable("Name", name);

    const ifcType = element.__proto__.constructor.name;
    createRowInTable("IfcType", ifcType);

    const type = element.ObjectType.value;
    createRowInTable("Object Type", type);

    const tag = element.Tag.value;
    createRowInTable("Tag", tag);

    // grab all propertyset lines in the file
    let lines = ifcapi.GetLineIDsWithType(modelID, IFCRELDEFINESBYPROPERTIES);

    // In the below array we will store the IDs of the Property Sets found
    let propSetIds = [];
    for (let i = 0; i < lines.size(); i++) {
        // Getting the ElementID from Lines
        let relatedID = lines.get(i);

        // Getting Element Data using the relatedID
        let relDefProps = ifcapi.GetLine(modelID, relatedID);

        // Boolean for Getting the IDs if relevant IDs are present
        let foundElement = false;

        // RelatedObjects is a property that is an Array of Objects. 
        // The way IFC is structured, Entities that use same property are included inside RelatedObjects
        // We Search inside RelatedObjects if our ElementID is present or not
        relDefProps.RelatedObjects.forEach((relID) => {
            if (relID.value === elementID) {
                foundElement = true;
            }
        });

        if (foundElement) {
            // Relevant IDs are found we then we go to RelatingPropertyDefinition
            // RelatingPropertyDefinition contain the IDs of Property Sets
            // But they should not be array, hence using (!Array.isArray())
            if (!Array.isArray(relDefProps.RelatingPropertyDefinition)) {
                let handle = relDefProps.RelatingPropertyDefinition;

                // Storing and pushing the IDs found in propSetIds Array
                propSetIds.push(handle.value);
            }
        }
    }

    // Getting the Property Sets from their IDs
    let propsets = propSetIds.map(id => ifcapi.GetLine(modelID, id, true));

    propsets.forEach((set) => {
        // There can multiple Property Sets
        set.HasProperties.forEach(p => {
            // We will check if the Values that are present are not null
            if (p.NominalValue != null) {
                if (p.NominalValue.label === "IFCBOOLEAN") {
                    // We will talk about this function in Frontend Part
                    createRowInTable(p.Name.value, p.NominalValue.value);
                }
                else {
                    // We will talk about this function in Frontend Part
                    createRowInTable(p.NominalValue.label, p.NominalValue.value);
                }
            }
        });
    });

    prop.appendChild(table);
}

export function createRowInTable(label, value) {
    const row = document.createElement("tr");
    row.innerHTML = "<td>" + label + "</td><td>" + value + "</td>";
    table.appendChild(row);
}


window.getPropertyWithExpressId = getPropertyWithExpressId;