import { IClientInformation } from "@/interfaces/client/IClientInformation";
import _ from 'lodash';
import { store } from "@/libs/store/store.js";

export class ClientsService {

  static getAllDifferentClients = (): Array<IClientInformation> => {
    let localAllDifferentClients = [];
    let dataByFloors = store.getters.getGeneralData;
    for (const [currentFloorKey, _currentFloorValue] of Object.entries(
      dataByFloors
    )) {
      let floorClients: Array<IClientInformation> = dataByFloors[currentFloorKey];
      for (const currentClient of floorClients) {
        if (
          !localAllDifferentClients.some(
            (currentLocalClient) =>
              currentLocalClient.clientName.toLowerCase() ===
                currentClient.clientName.toLowerCase() &&
              currentLocalClient.zoneName.toLowerCase() ===
                currentClient.zoneName.toLowerCase()
          )
        ) {
          localAllDifferentClients.push({
            clientName: currentClient.clientName,
            zoneName: currentClient.zoneName,
            color: currentClient.color,
          });
        }
      }
    }
  
    localAllDifferentClients.sort(
      (a, b) =>
        a.clientName.localeCompare(b.clientName) ||
        a.zoneName.localeCompare(b.zoneName)
    );
  
    return localAllDifferentClients;
  };

  static getClientById = (cid: string, dataByFloors: Array<IClientInformation>) => {
    for (const [currentFloorKey, currentFloorValue] of Object.entries(
      dataByFloors
    )) {
      let floorClients: Array<IClientInformation> = dataByFloors[currentFloorKey];
      for (const currentClient of floorClients) {
        if (cid.includes(currentClient.clientId)) {
          return currentClient;
        }
      }
    }
  }

  /**
   * 
   * @param client 
   * @returns 
   */
  static isClientVisible = (client): boolean => {
    let returnValue = false;
    store.getters.getClients.forEach((t) => {
      if (t.clientName + t.zoneName === client.clientName + client.zoneName) {
        returnValue = t.clientChecked;
      }
    });
    return returnValue;
  };
}