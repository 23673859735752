export const geometryTypes = new Set([
    "IfcCartesianPoint",
    "IfcArbitraryClosedProfileDef",
    "IfcDirection",
    "IfcAxis2Placement3D",
    "IfcAxis2Placement2D",
    "IfcLocalPlacement",
    "IfcShapeRepresentation",
    "IfcRectangleProfileDef",
    "IfcPolyline",
    "IfcPlane",
    "IfcExtrudedAreaSolid",
    "IfcSurfaceStyleRendering",
    "IfcSurfaceStyle",
    "IfcPresentationStyleAssignment",
    "IfcStyledRepresentation",
    "IfcCartesianTransformationOperator3D",
    "IfcColourRgb",
    "IfcCartesianTransformationOperator3DNonUniform",
    "IfcStyledItem",
    "IfcCurveBoundedPlane",
    "IfcConnectionSurfaceGeometry",
    "IfcProductDefinitionShape",
    "IfcGeometricRepresentationContext",
    "IfcGeometricRepresentationSubcontext",
    "IfcPolyLoop",
    "IfcFaceOuterBound",
    "IfcFace",
    "IfcCartesianTransformationOperator3DnonUniform",
    "IfcFaceBound",
    "IfcClosedShell",
    "IfcFacetedBrep",
    "IfcRepresentationMap",
    "IfcMappedItem",
    "IfcRelFillsElement",
    "IfcArbitraryProfileDefWithVoids",
    "IfcCompositeCurve",
    "IfcCompositeCurveSegment",
    "IfcCircle",
    "IfcEllipse",
    "IfcCircleHollowProfileDef",
    "IfcRectangleHollowProfileDef",
    "IfcLine",
    "IfcIShapeProfileDef",
    "IfcGeometricRepresentationContext",
    "IfcGeometricRepresentationSubContext",
    "IfcCurveStyle",
    "IfcDraughtingPreDefinedCurveFont",
    "IfcGeometricSet",
    "IfcCircleProfileDef",
    "IfcPolygonalBoundedHalfSpace",
    "IfcFaceBasedSurfaceModel",
    "IfcConnectedFaceSet",
    "IfcTrimmedCurve",
    "IfcOpenShell",
    "IfcShellBasedSurfaceModel",
    "IfcHalfSpaceSolid",
    "IfcBooleanClippingResult",
    "IfcGeometricCurveSet",
    "IfcCurveStyleFont",
    "IfcCurveStyleFontPattern"
]);