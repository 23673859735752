import { createStore, mapGetters } from "vuex";
import {
  DateUtils
} from "../../utils/dateUtils";
import {
  getClientDataGroupedByFloor,
  getSimpleClientDataGroupedByFloor,
  getAllRocadesWithRangeDates,
  getPhaseDatesFromFile,
  getReferencesPhaseDatesFromFile
} from "../../scripts/xlsx-parser";
import _ from 'lodash';

export const main = {
  state: () => ({
      isFirstLoadingFinished: false,
      rocades: [],
      generalData: null, //used as main source of data - /!\ Do not alter it
      generalData2DVisibility: [], //used to handle/display 2D data on dates view
      generalData2DPhasesVisibility: [], //used to handle/display 2D data on phases view
      isFilters2DOpened: false, //used to display/hide filters 2d window
      isFilters3DOpened: true, // used to display/hide filters 3d window
      allHeaderPhases: [], //contains computed phases
      allMinimalPhases: [], //contains minimal phases of project
      allReferencesPhases: [], //contains references phases of project (special demand of architects for phases view - avoid superposition of areas)
      rangesFilter: [], //contains every date ranged filters
      activatedClients2D: [], //contains every selected clients on 2D view
      activatedClients2DPhases: [], //contains every selected clients on 2D phases view
      allDifferentClients: [], //contains every clients (no duplicates)
      allDifferentFloors: [],
      allDifferentPhases: [],
      activatedFloors2D: [],
      activatedFloors2DPhases: [],
      isUserLogged: false,
      timelineSelection: 0,
      is2DDataLoaded: true,
      is2DPhasesDataLoaded: true,
      isAllDataLoaded: false,

      colorClientMapping: {},
      floorVisibility: [],
      phaseVisibility: [],
      clientVisibility: [],
      selectedFilterDate: null,


      allPreloadedFloorPlans: []
  }),

  getters: {
    isFirstLoadingFinished(state) {
      return state.isFirstLoadingFinished;
    },
    getIsAllDataLoaded(state) {
      return state.isAllDataLoaded;
    },
    getGeneralData(state) {
      return state.generalData;
    },
    getAllDifferentPhases(state) {
      return state.allDifferentPhases;
    },
    getIs2DDataLoaded(state) {
      return state.is2DDataLoaded;
    },
    getIs2DPhasesDataLoaded(state) {
      return state.is2DPhasesDataLoaded;
    },
    getTimelineSelection(state) {
      return state.timelineSelection;
    },
    getIsUserLogged(state) {
      return state.isUserLogged;
    },
    getRangesFilter(state) {
      return state.rangesFilter;
    },
    getActivatedClients2D(state) {
      return state.activatedClients2D;
    },
    getActivatedFloors2D(state) {
      return state.activatedFloors2D;
    },
    getActivatedClients2DPhases(state) {
      return state.activatedClients2DPhases;
    },
    getActivatedFloors2DPhases(state) {
      return state.activatedFloors2DPhases;
    },
    getAllDifferentClients(state) {
      return state.allDifferentClients;
    },
    getAllDifferentFloors(state) {
      return state.allDifferentFloors;
    },
    getAllHeaderPhases(state) {
      return state.allHeaderPhases;
    },
    getAllMinimalPhases(state) {
      return state.allMinimalPhases
    },
    getAllReferencesPhases(state) {
      return state.allReferencesPhases
    },
    getIsFilters2DOpened(state) {
      return state.isFilters2DOpened;
    },
    getIsFilters3DOpened(state) {
      return state.isFilters3DOpened;
    },
    getGeneralData2DVisibility(state) {
      return state.generalData2DVisibility;
    },
    getGeneralData2DPhasesVisibility(state) {
      return state.generalData2DPhasesVisibility;
    },
    getRocades(state) {
      return state.rocades;
    },
    colorClientMapping(state) {
      return state.colorClientMapping;
    },
    allColorClients(state) {
      return Object.entries(state.colorClientMapping);
    },
    floorVisibility(state) {
      return state.floorVisibility;
    },
    phaseVisibility(state) {
      return state.phaseVisibility;
    },
    clientVisibility(state) {
      return state.clientVisibility;
    },
    getSelectedFilterDate(state) {
      return state.selectedFilterDate;
    },
    getAllPreloadedFloorPlans(state) {
      return state.allPreloadedFloorPlans;
    },

    getClients(state) {
      return state.clientVisibility;
    },

    getFloors(state) {
      return state.floorVisibility;
    }
  },

  mutations: {
    mutationSetIsAllDataLoaded(state, data) {
      state.isAllDataLoaded = data;
    },
    mutationSetSelectedFilterDate(state, data) {
      state.selectedFilterDate = data;
    },
    mutationSetGeneralData(state, data) {
      state.generalData = data;
      state.isFirstLoadingFinished = true;
    },
    mutationSetAllDifferentPhases(state, data) {
      state.allDifferentPhases = data;
    },
    mutationSetIs2DDataLoaded(state, data) {
      state.is2DDataLoaded = data;
    },
    mutationSetIs2DPhasesDataLoaded(state, data) {
      state.is2DPhasesDataLoaded = data;
    },
    mutationSetTimelineSelection(state, data) {
      state.timelineSelection = data;
    },
    mutationSetIsUserLogged(state, data) {
      state.isUserLogged = data;
    },
    mutationSetAllHeaderPhases(state, data) {
      state.allHeaderPhases = data;
    },
    mutationSetAllMinimalPhases(state, data) {
      state.allMinimalPhases = data;
    },
    mutationSetAllReferencesPhases(state, data) {
      state.allReferencesPhases = data;
    },
    mutationSetActivatedClients2D(state, data) {
      state.activatedClients2D = data;
    },
    mutationSetActivatedClients2DPhases(state, data) {
      state.activatedClients2DPhases = data;
    },
    mutationSetAllDifferentClients(state, data) {
      state.allDifferentClients = data;
    },
    mutationSetActivatedFloors2D(state, data) {
      state.activatedFloors2D = data;
    },
    mutationSetActivatedFloors2DPhases(state, data) {
      state.activatedFloors2DPhases = data;
    },
    mutationSetAllDifferentFloors(state, data) {
      state.allDifferentFloors = data;
    },
    mutationSetRangesFilter(state, data) {
      state.rangesFilter = data;
    },
    mutationSetIsFilters2DOpened(state, data) {
      state.isFilters2DOpened = data;
    },
    mutationSetIsFilters3DOpened(state, data) {
      state.isFilters3DOpened = data;
    },
    mutationSetGeneralData2D(state, data) {
      state.generalData2DVisibility = data;
    },
    mutationInitializeGeneralData2D(state) {
      state.generalData2DVisibility = state.generalData;
    },
    mutationSetGeneralData2DPhases(state, data) {
      state.generalData2DPhasesVisibility = data;
    },
    mutationInitializeGeneralData2DPhases(state, data) {
      state.generalData2DPhasesVisibility = data;
    },
    mutationSetRocades(state, data) {
      state.rocades = data;
    },
    setFloorVisibility(state, [key, value]) {
      const temp = _.cloneDeep(state.floorVisibility);
      for (const floor of temp) {
        if (floor.nb === key) {
          floor.checked = value;
        }
      }

      state.floorVisibility = [...temp];
    },
    setPhaseVisibility(state, [key, value]) {
      state.phaseVisibility[key] = value;
    },
    setClientVisibility(state, [key, value]) {
      state.clientVisibility[key] = value;
    },
    setColorClientMapping(state, mapping) {
      state.colorClientMapping = mapping;
    },

    mutationInitClientsVisibility(state, initialVisibilityArray) {
      state.clientVisibility = _.cloneDeep(initialVisibilityArray);
      state.clientVisibility.forEach((t) => {
        t.clientChecked = true;
      })
      state.clientVisibility.sort(function (a, b) {
        return a.clientName.localeCompare(b.clientName) || a.zoneName.localeCompare(b.zoneName);
      })
    },
    mutationInitFloorsVisibility(state, initialVisibilityArray) {
      state.floorVisibility = _.cloneDeep(initialVisibilityArray);
    },

    mutationToggleOffAllClientVisibility(state, payload) {
      state.clientVisibility.forEach((t) => {
        t.clientChecked = false;
      });
    },
    mutationToggleOnAllClientVisibility(state, payload) {
      state.clientVisibility.forEach((t) => {
        t.clientChecked = true;
      });
    },
    mutationToggleOffAllFloorVisibility(state, payload) {
      let temp = _.cloneDeep(state.floorVisibility);
      for (const floor of temp) {
        floor.checked = false;
      }
      state.floorVisibility = [...temp];
    },
    mutationToggleOnAllFloorVisibility(state, payload) {
      let temp = _.cloneDeep(state.floorVisibility);
      for (const floor of temp) {
        floor.checked = true;
      }
      state.floorVisibility = [...temp];
    },

    mutationAllPreloadedFloorPlans(state, data) {
      state.allPreloadedFloorPlans = [...data];
    }
  },
  actions: {
    async actionParseFirstData(context) {
      console.time("actionParseFirstData");
      const data = await Promise.resolve(getClientDataGroupedByFloor()).then();
      console.log("DATA:", data);
      const simpleData = await Promise.resolve(getSimpleClientDataGroupedByFloor());

      context.commit("mutationSetGeneralData", _.cloneDeep(data));
      context.commit("mutationSetGeneralData2D", _.cloneDeep(data));
      context.commit("mutationSetGeneralData2DPhases", _.cloneDeep(data));
      context.commit("mutationInitializeGeneralData2DPhases", _.cloneDeep(simpleData));
      
      console.timeEnd("actionParseFirstData");
    },
    actionSetGeneralData2D(context, newData) {
      context.commit("mutationSetGeneralData2D", _.cloneDeep(newData));
    },
    actionSetGeneralData2DPhases(context, newData) {
      context.commit("mutationSetGeneralData2DPhases", _.cloneDeep(newData));
    },
    async actionSetAllDifferentPhases(context) {
      const newData = await Promise.resolve(getPhaseDatesFromFile());
      context.commit("mutationSetAllDifferentPhases", _.cloneDeep(newData));
    },
    async actionSetAllReferencesPhases(context) {
      console.time("actionSetAllReferencesPhases");
      const newData = await Promise.resolve(getReferencesPhaseDatesFromFile());
      let formattedData = Object.values(newData).map((phase) => {
        return {
          name: phase.phase_name,
          startDate: DateUtils.generateDateFromString(DateUtils.splitDateRange(phase.date, "_")[0]),
          endDate: DateUtils.generateDateFromString(DateUtils.splitDateRange(phase.date, "_")[1])
        }
      });
      context.commit("mutationSetAllReferencesPhases", formattedData);
      
      console.timeEnd("actionSetAllReferencesPhases");
    },
    actionSetIs2DDataLoaded(context, newData) {
      context.commit("mutationSetIs2DDataLoaded", newData);
    },
    actionSetIs2DPhasesDataLoaded(context, newData) {
      context.commit("mutationSetIs2DPhasesDataLoaded", newData);
    },
    actionSetIsUserLogged(context, newData) {
      context.commit("mutationSetIsUserLogged", newData);
    },
    actionSetActivatedClients2D(context, newData) {
      context.commit("mutationSetActivatedClients2D", _.cloneDeep(newData));
    },
    actionSetActivatedClients2DPhases(context, newData) {
      context.commit("mutationSetActivatedClients2DPhases", _.cloneDeep(newData));
    },
    actionSetAllDifferentClients(context, newData) {
      context.commit("mutationSetAllDifferentClients", _.cloneDeep(newData))
    },
    actionSetActivatedFloors2D(context, newData) {
      context.commit("mutationSetActivatedFloors2D", _.cloneDeep(newData));
    },
    actionSetActivatedFloors2DPhases(context, newData) {
      context.commit("mutationSetActivatedFloors2DPhases", _.cloneDeep(newData));
    },
    actionSetAllHeaderPhases(context, newData) {
      context.commit("mutationSetAllHeaderPhases", newData);
    },
    actionSetAllMinimalPhases(context, newData) {
      context.commit("mutationSetAllMinimalPhases", newData);
    },
    actionSetRangesFilter(context, newData) {
      context.commit("mutationSetRangesFilter", newData);
    },
    actionSetIsFilters2DOpened(context, newData) {
      context.commit('mutationSetIsFilters2DOpened', newData);
    },
    actionSetIsFilters3DOpened(context, newData) {
      context.commit('mutationSetIsFilters3DOpened', newData);
    },
    async actionParseRocades(context) {
      const rocades = await getAllRocadesWithRangeDates();
      context.commit("mutationSetRocades", rocades)
    },
    async actionInitClientsVisibility(context, newData) {
      context.commit("mutationInitClientsVisibility", newData);
    },
    actionInitFloorsVisibility(context, floors) {
      context.commit("mutationInitFloorsVisibility", floors);
    },
  },

  computed: {
    ...mapGetters({
      colorClientMapping: "colorClientMapping",
      floorVisibility: "floorVisibility",
      phaseVisibility: "phaseVisibility",
      clientVisibility: "clientVisibility",
    }),
  },
};

export const canvas = {
  state: () => ({
    renderer: null,
    scene: null,
    mouse3D: null,
    container: null,
    controls: null,
  }),

  getters: {
    getRenderer(state) {
      return state.renderer;
    },

    getScene(state) {
      return state.scene;
    }
  },

  mutations: {
    mutationInitRenderer(state, data) {
      state.renderer = data;
    },
  },
};

export const store = createStore({
  modules: {
    main: main,
    canvas:canvas,
  }
});