import * as THREE from 'three'
import {readColorFile} from '@/scripts/xlsx-parser';
export class Color {
    static WHITE = "0xffffff";
    static UNDERCONSTRUCTION = "#ffff00";
    static BACKGROUND_CANVAS = "hsl(0, 0%, 100%)";
    static BACKGROUND_FLOOR_HSL = new THREE.Color("hsl(0, 0%, 0%)");

    static convertDecimalFromHex(hexString: string) {
        return parseInt(hexString, 16);
    }

    static convertHexFromDecimal(decimalNumber: number) {
        const asHexString = '#' + decimalNumber.toString(16).padStart(6, '0');
        return asHexString
    }

    static getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static getRandomRGBAColor() {
        const o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 1 + ')';
    }

    static async generateAllClientsColors(): Promise<Map<string, string>> {
        const xlsData = await Promise.resolve(readColorFile());
        let mapClientsColors = new Map();

        for(let clientIndex = 0; clientIndex < xlsData.length; ++clientIndex) {
            mapClientsColors.set(xlsData[clientIndex].clientName.toUpperCase(), xlsData[clientIndex].hex_color);
        }
        
        return mapClientsColors;
    }
}