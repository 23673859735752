import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./libs/store/store";
import VueExcelXlsx from "vue-excel-xlsx";
import Loading from 'vue3-loading-screen';
import paper from 'paper';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

require('@/styles/global.scss');
require('@/styles/print.scss');

const app = createApp(App);
app.use(store)
    .use(paper)
    .use(router)
    .use(VueExcelXlsx)
    .use(Loading, {
        bg: 'rgba(255,255,255,0.5)',
        slot: `
        <div class="loader-wrapper">
            <div class="loader is-loading"></div>
        </div>
      `
    })
    .component('Datepicker', Datepicker)
    .mount("#app");


export function setLoading(value: boolean): void {
    app.config.globalProperties.$isLoading(value);
}