import axios from "axios";
import { read, utils } from "xlsx";
import { Color } from "@/utils/colorUtils";
import { DateUtils } from "@/utils/dateUtils";
import { IFCLoader } from "web-ifc-three/IFCLoader";
import { LoadFileData } from "./ifc-loader.js";
import { range } from "lodash";

export function getClientDataGroupedByFloor() {
   return groupClientsByFloor(getGeneralClientsDataFromFile());
}

export function getSimpleClientDataGroupedByFloor() {
   return groupClientsByFloor(getGeneralClientsDataSimpleFromFile());
}

export function getDateRanges() {
   return getAllDifferentDateRanges();
}

export function getAllRocadeNames() {
   return getAllRocadesWithRangeDates();
}

export function getSlabCoordinatesByFileName(name) {
   let slabCoordinates = {
      "rocade_fond_en1_phase00": [
         [
            -31.99,
            85.23,
            -31.99,
            85.22,
            -75.05,
            85.22,
            -75.03,
            80.85,
            -111,
            80.85,
            -110.98,
            80.85,
            -108.96,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -71.03,
            43.01,
            -71.03,
            43.41,
            -61.2,
            43.41,
            -61.2,
            43.57,
            -58.85,
            43.57,
            -58.85,
            43.82,
            -54.68,
            43.82,
            -54.68,
            43.11,
            -50.48,
            43.11,
            -50.48,
            43.25,
            -45.45,
            43.25,
            -45.45,
            43.37,
            -33.71,
            43.31,
            -33.71,
            43.44,
            -32.86,
            43.46,
            -32.86,
            43.62,
            -30.87,
            43.6,
            -30.87,
            42.7,
            -15.5,
            42.53,
            -15.5,
            42.63,
            -3.6,
            42.5,
            -3.59,
            42.41,
            -4.09,
            42.37,
            -4.05,
            41.91,
            -3.55,
            41.9,
            -3.54,
            41.74,
            -3.04,
            41.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.45,
            42.89,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.79,
            49.79,
            -2.9,
            49.86,
            -5.71,
            85.3,
            -5.89,
            85.28,
            -5.89,
            85.25,
            -6.07,
            85.23,
            -31.99,
            85.23
         ]
      ],
      "rocade_fond_en1_phase01": [
         [
            -31.99,
            85.23,
            -31.99,
            85.22,
            -75.05,
            85.22,
            -75.03,
            80.85,
            -111,
            80.85,
            -110.98,
            80.85,
            -108.96,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -71.03,
            43.01,
            -71.03,
            43.41,
            -61.2,
            43.41,
            -61.2,
            43.57,
            -58.85,
            43.57,
            -58.85,
            43.82,
            -54.68,
            43.82,
            -54.68,
            43.11,
            -50.48,
            43.11,
            -50.48,
            43.25,
            -45.45,
            43.25,
            -45.45,
            43.37,
            -33.71,
            43.31,
            -33.71,
            43.44,
            -32.86,
            43.46,
            -32.86,
            43.62,
            -30.87,
            43.6,
            -30.87,
            42.7,
            -15.5,
            42.53,
            -15.5,
            42.63,
            -3.6,
            42.5,
            -3.59,
            42.41,
            -4.09,
            42.37,
            -4.05,
            41.91,
            -3.55,
            41.9,
            -3.54,
            41.74,
            -3.04,
            41.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.45,
            42.89,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.79,
            49.79,
            -2.9,
            49.86,
            -5.71,
            85.3,
            -5.89,
            85.28,
            -5.89,
            85.25,
            -6.07,
            85.23,
            -31.99,
            85.23
         ]
      ],
      "rocade_fond_en1_phase02_03": [
         [
            -31.99,
            85.23,
            -31.99,
            85.22,
            -75.05,
            85.22,
            -75.03,
            80.85,
            -111,
            80.85,
            -110.98,
            80.85,
            -108.96,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -71.03,
            43.01,
            -71.03,
            43.41,
            -61.2,
            43.41,
            -61.2,
            43.57,
            -58.85,
            43.57,
            -58.85,
            43.82,
            -54.68,
            43.82,
            -54.68,
            43.11,
            -50.48,
            43.11,
            -50.48,
            43.25,
            -45.45,
            43.25,
            -45.45,
            43.37,
            -33.71,
            43.31,
            -33.71,
            43.44,
            -32.86,
            43.46,
            -32.86,
            43.62,
            -30.87,
            43.6,
            -30.87,
            42.7,
            -15.5,
            42.53,
            -15.5,
            42.63,
            -3.6,
            42.5,
            -3.59,
            42.41,
            -4.09,
            42.37,
            -4.05,
            41.91,
            -3.55,
            41.9,
            -3.54,
            41.74,
            -3.04,
            41.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.45,
            42.89,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.79,
            49.79,
            -2.9,
            49.86,
            -5.71,
            85.3,
            -5.89,
            85.28,
            -5.89,
            85.25,
            -6.07,
            85.23,
            -31.99,
            85.23
         ]
      ],
      "rocade_fond_en1_phase04_05": [
         [
            -20.83,
            85.23,
            -6.96,
            85.23,
            -6.44,
            85.07,
            -6.01,
            84.74,
            -5.71,
            84.29,
            -5.57,
            83.76,
            -4.26,
            67.17,
            -4.31,
            66.64,
            -4.52,
            66.16,
            -4.88,
            65.77,
            -5.35,
            65.51,
            -5.87,
            65.42,
            -7.14,
            65.42,
            -7.49,
            65.33,
            -7.74,
            65.08,
            -7.83,
            64.73,
            -7.83,
            61.18,
            -7.74,
            60.84,
            -7.49,
            60.59,
            -7.14,
            60.49,
            -5.4,
            60.49,
            -4.87,
            60.41,
            -4.38,
            60.18,
            -3.99,
            59.82,
            -3.72,
            59.35,
            -3.6,
            58.83,
            -2.94,
            50.56,
            -3.12,
            42.44,
            -3.04,
            41.78,
            -3.54,
            41.74,
            -3.55,
            41.9,
            -4.05,
            41.91,
            -4.09,
            42.37,
            -4.09,
            42.51,
            -15.5,
            42.63,
            -15.5,
            42.53,
            -30.87,
            42.7,
            -30.87,
            43.6,
            -32.86,
            43.62,
            -32.86,
            43.44,
            -33.71,
            43.44,
            -33.71,
            43.31,
            -45.45,
            43.37,
            -45.45,
            43.25,
            -50.48,
            43.25,
            -50.48,
            43.11,
            -54.68,
            43.11,
            -54.68,
            43.83,
            -56.13,
            43.83,
            -56.13,
            43.82,
            -58.85,
            43.82,
            -58.85,
            43.57,
            -60.25,
            43.57,
            -60.25,
            43.26,
            -66.9,
            43.26,
            -71.03,
            43.26,
            -71.03,
            43.01,
            -74.63,
            43.01,
            -74.63,
            43.06,
            -108.86,
            43.06,
            -108.96,
            43.06,
            -110.98,
            80.85,
            -108,
            80.85,
            -75.05,
            80.85,
            -75.05,
            85.22,
            -71.65,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.23,
            -24.27,
            85.23,
            -24.27,
            85.22,
            -24.15,
            85.22,
            -24.15,
            85.23,
            -20.83,
            85.23
         ]
      ],
      "rocade_fond_en1_phase06": [
         [
            -20.83,
            85.23,
            -6.96,
            85.23,
            -6.44,
            85.07,
            -6.01,
            84.74,
            -5.71,
            84.29,
            -5.57,
            83.76,
            -4.26,
            67.17,
            -4.31,
            66.64,
            -4.52,
            66.16,
            -4.88,
            65.77,
            -5.35,
            65.51,
            -5.87,
            65.42,
            -7.14,
            65.42,
            -7.49,
            65.33,
            -7.74,
            65.08,
            -7.83,
            64.73,
            -7.83,
            61.18,
            -7.74,
            60.84,
            -7.49,
            60.59,
            -7.14,
            60.49,
            -5.4,
            60.49,
            -4.87,
            60.41,
            -4.38,
            60.18,
            -3.99,
            59.82,
            -3.72,
            59.35,
            -3.6,
            58.83,
            -2.94,
            50.56,
            -3.12,
            42.44,
            -3.04,
            41.78,
            -3.54,
            41.74,
            -3.55,
            41.9,
            -4.05,
            41.91,
            -4.09,
            42.37,
            -4.09,
            42.51,
            -15.5,
            42.63,
            -15.5,
            42.53,
            -30.87,
            42.7,
            -30.87,
            43.6,
            -32.86,
            43.62,
            -32.86,
            43.44,
            -33.71,
            43.44,
            -33.71,
            43.31,
            -45.45,
            43.37,
            -45.45,
            43.25,
            -50.48,
            43.25,
            -50.48,
            43.11,
            -54.68,
            43.11,
            -54.68,
            43.83,
            -56.13,
            43.83,
            -56.13,
            43.82,
            -58.85,
            43.82,
            -58.85,
            43.57,
            -60.25,
            43.57,
            -60.25,
            43.26,
            -66.9,
            43.26,
            -71.03,
            43.26,
            -71.03,
            43.01,
            -74.63,
            43.01,
            -74.63,
            43.06,
            -108.86,
            43.06,
            -108.96,
            43.06,
            -110.98,
            80.85,
            -108,
            80.85,
            -75.05,
            80.85,
            -75.05,
            85.22,
            -71.65,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.23,
            -24.27,
            85.23,
            -24.27,
            85.22,
            -24.15,
            85.22,
            -24.15,
            85.23,
            -20.83,
            85.23
         ]
      ],
      "rocade_fond_es1_phase00": [
         [
            -11.65,
            85.2,
            -75.05,
            85.22,
            -75.05,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -84.03,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.54,
            30.8,
            -70.93,
            30.8,
            -70.97,
            28.63,
            -73.81,
            28.63,
            -73.81,
            29.13,
            -80.42,
            29.13,
            -80.42,
            29.55,
            -82.68,
            29.55,
            -82.61,
            24.14,
            -85.48,
            24.15,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.97,
            11.27,
            -84.97,
            12.16,
            -85.04,
            12.16,
            -84.98,
            15.91,
            -80.7,
            15.93,
            -80.7,
            22.48,
            -76.88,
            22.53,
            -76.88,
            24.51,
            -67.93,
            24.67,
            -67.93,
            23.97,
            -69.01,
            23.97,
            -69.01,
            18.48,
            -67.9,
            18.48,
            -67.9,
            15.06,
            -63.53,
            15.06,
            -63.53,
            24.7,
            -66.5,
            24.7,
            -66.37,
            28.57,
            -68.39,
            28.64,
            -68.39,
            30.01,
            -68.99,
            30.03,
            -68.99,
            30.78,
            -62.91,
            30.78,
            -62.91,
            29.36,
            -61.55,
            29.36,
            -61.54,
            30.76,
            -54.17,
            30.7,
            -54.17,
            29.91,
            -54.99,
            29.91,
            -55.03,
            26.85,
            -53.24,
            26.82,
            -53.27,
            24.07,
            -53.77,
            24.08,
            -53.9,
            12.54,
            -46.29,
            12.44,
            -20.43,
            12.5,
            -14.58,
            12.42,
            -14.57,
            12.82,
            -9.05,
            12.8,
            -9.04,
            13.45,
            -4.03,
            13.38,
            -3.84,
            28.29,
            -2.03,
            28.32,
            -3.13,
            42.95,
            -3.11,
            42.95,
            -6.64,
            85.3,
            -11.65,
            85.2
         ]
      ],
      "rocade_fond_es1_phase01": [
         [
            -11.65,
            85.2,
            -75.05,
            85.22,
            -75.05,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -84.03,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.54,
            30.8,
            -70.93,
            30.8,
            -70.97,
            28.63,
            -73.81,
            28.63,
            -73.81,
            29.13,
            -80.42,
            29.13,
            -80.42,
            29.55,
            -82.68,
            29.55,
            -82.61,
            24.14,
            -85.48,
            24.15,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.97,
            11.27,
            -84.97,
            12.16,
            -85.04,
            12.16,
            -84.98,
            15.91,
            -80.7,
            15.93,
            -80.7,
            22.48,
            -76.88,
            22.53,
            -76.88,
            24.51,
            -67.93,
            24.67,
            -67.93,
            23.97,
            -69.01,
            23.97,
            -69.01,
            18.48,
            -67.9,
            18.48,
            -67.9,
            15.06,
            -63.53,
            15.06,
            -63.53,
            24.7,
            -66.5,
            24.7,
            -66.37,
            28.57,
            -68.39,
            28.64,
            -68.39,
            30.01,
            -68.99,
            30.03,
            -68.99,
            30.78,
            -62.91,
            30.78,
            -62.91,
            29.36,
            -61.55,
            29.36,
            -61.54,
            30.76,
            -54.17,
            30.7,
            -54.17,
            29.91,
            -54.99,
            29.91,
            -55.03,
            26.85,
            -53.24,
            26.82,
            -53.27,
            24.07,
            -53.77,
            24.08,
            -53.9,
            12.54,
            -46.29,
            12.44,
            -20.43,
            12.5,
            -14.58,
            12.42,
            -14.57,
            12.82,
            -9.05,
            12.8,
            -9.04,
            13.45,
            -4.03,
            13.38,
            -3.84,
            28.29,
            -2.03,
            28.32,
            -3.13,
            42.95,
            -3.11,
            42.95,
            -6.64,
            85.3,
            -11.65,
            85.2
         ]
      ],
      "rocade_fond_es1_phase02_03": [
         [
            -11.65,
            85.2,
            -75.05,
            85.22,
            -75.05,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -84.03,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.54,
            30.8,
            -70.93,
            30.8,
            -70.97,
            28.63,
            -73.81,
            28.63,
            -73.81,
            29.13,
            -80.42,
            29.13,
            -80.42,
            29.55,
            -82.68,
            29.55,
            -82.61,
            24.14,
            -85.48,
            24.15,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.97,
            11.27,
            -84.97,
            12.16,
            -85.04,
            12.16,
            -84.98,
            15.91,
            -80.7,
            15.93,
            -80.7,
            22.48,
            -76.88,
            22.53,
            -76.88,
            24.51,
            -67.93,
            24.67,
            -67.93,
            23.97,
            -69.01,
            23.97,
            -69.01,
            18.48,
            -67.9,
            18.48,
            -67.9,
            15.06,
            -63.53,
            15.06,
            -63.53,
            24.7,
            -66.5,
            24.7,
            -66.37,
            28.57,
            -68.39,
            28.64,
            -68.39,
            30.01,
            -68.99,
            30.03,
            -68.99,
            30.78,
            -62.91,
            30.78,
            -62.91,
            29.36,
            -61.55,
            29.36,
            -61.54,
            30.76,
            -54.17,
            30.7,
            -54.17,
            29.91,
            -54.99,
            29.91,
            -55.03,
            26.85,
            -53.24,
            26.82,
            -53.27,
            24.07,
            -53.77,
            24.08,
            -53.9,
            12.54,
            -46.29,
            12.44,
            -20.43,
            12.5,
            -14.58,
            12.42,
            -14.57,
            12.82,
            -9.05,
            12.8,
            -9.04,
            13.45,
            -4.03,
            13.38,
            -3.84,
            28.29,
            -2.03,
            28.32,
            -3.13,
            42.95,
            -3.11,
            42.95,
            -6.64,
            85.3,
            -11.65,
            85.2
         ]
      ],
      "rocade_fond_es1_phase04_05": [
         [
            -74.46,
            43.11,
            -75.38,
            43.11,
            -75.38,
            43.53,
            -81.37,
            43.53,
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -109.79,
            68.12,
            -110.44,
            80.34,
            -104.94,
            80.35,
            -75.05,
            80.39,
            -75.05,
            85.22,
            -74.9,
            85.22,
            -11.65,
            85.2,
            -6.64,
            85.3,
            -3.11,
            42.95,
            -3.13,
            42.95,
            -3.07,
            42.11,
            -2.03,
            28.32,
            -3.84,
            28.29,
            -4.03,
            13.38,
            -9.04,
            13.45,
            -9.05,
            12.8,
            -11.76,
            12.84,
            -14.57,
            12.82,
            -14.58,
            12.42,
            -21.37,
            12.51,
            -46.37,
            12.44,
            -53.9,
            12.54,
            -53.77,
            24.08,
            -53.27,
            24.07,
            -53.24,
            26.82,
            -55.03,
            26.85,
            -54.99,
            29.91,
            -54.17,
            29.91,
            -54.17,
            30.7,
            -54.22,
            30.7,
            -61.55,
            30.76,
            -61.55,
            29.36,
            -62.91,
            29.36,
            -62.91,
            30.78,
            -68.99,
            30.78,
            -68.99,
            30.03,
            -68.39,
            30.01,
            -68.39,
            28.64,
            -66.37,
            28.57,
            -66.5,
            24.7,
            -63.53,
            24.7,
            -63.53,
            15.06,
            -67.9,
            15.06,
            -67.9,
            18.48,
            -69.01,
            18.48,
            -69.01,
            23.97,
            -67.93,
            23.97,
            -67.93,
            24.67,
            -76.88,
            24.51,
            -76.88,
            22.53,
            -80.7,
            22.48,
            -80.7,
            15.93,
            -84.98,
            15.91,
            -85.04,
            12.16,
            -84.97,
            12.16,
            -84.97,
            11.27,
            -84.84,
            11.27,
            -84.85,
            10.72,
            -86.08,
            10.74,
            -86.06,
            12.18,
            -85.68,
            12.17,
            -85.48,
            24.15,
            -82.61,
            24.14,
            -82.68,
            29.55,
            -80.42,
            29.55,
            -80.42,
            29.13,
            -73.81,
            29.13,
            -73.81,
            28.63,
            -70.97,
            28.63,
            -70.93,
            30.8,
            -74.54,
            30.8,
            -74.46,
            43.11
         ]
      ],
      "rocade_fond_es1_phase06": [
         [
            -74.46,
            43.11,
            -75.38,
            43.11,
            -75.38,
            43.53,
            -81.37,
            43.53,
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -109.79,
            68.12,
            -110.44,
            80.34,
            -104.94,
            80.35,
            -75.05,
            80.39,
            -75.05,
            85.22,
            -74.9,
            85.22,
            -11.65,
            85.2,
            -6.64,
            85.3,
            -3.11,
            42.95,
            -3.13,
            42.95,
            -3.07,
            42.11,
            -2.03,
            28.32,
            -3.84,
            28.29,
            -4.03,
            13.38,
            -9.04,
            13.45,
            -9.05,
            12.8,
            -11.76,
            12.84,
            -14.57,
            12.82,
            -14.58,
            12.42,
            -21.37,
            12.51,
            -46.37,
            12.44,
            -53.9,
            12.54,
            -53.77,
            24.08,
            -53.27,
            24.07,
            -53.24,
            26.82,
            -55.03,
            26.85,
            -54.99,
            29.91,
            -54.17,
            29.91,
            -54.17,
            30.7,
            -54.22,
            30.7,
            -61.55,
            30.76,
            -61.55,
            29.36,
            -62.91,
            29.36,
            -62.91,
            30.78,
            -68.99,
            30.78,
            -68.99,
            30.03,
            -68.39,
            30.01,
            -68.39,
            28.64,
            -66.37,
            28.57,
            -66.5,
            24.7,
            -63.53,
            24.7,
            -63.53,
            15.06,
            -67.9,
            15.06,
            -67.9,
            18.48,
            -69.01,
            18.48,
            -69.01,
            23.97,
            -67.93,
            23.97,
            -67.93,
            24.67,
            -76.88,
            24.51,
            -76.88,
            22.53,
            -80.7,
            22.48,
            -80.7,
            15.93,
            -84.98,
            15.91,
            -85.04,
            12.16,
            -84.97,
            12.16,
            -84.97,
            11.27,
            -84.84,
            11.27,
            -84.85,
            10.72,
            -86.08,
            10.74,
            -86.06,
            12.18,
            -85.68,
            12.17,
            -85.48,
            24.15,
            -82.61,
            24.14,
            -82.68,
            29.55,
            -80.42,
            29.55,
            -80.42,
            29.13,
            -73.81,
            29.13,
            -73.81,
            28.63,
            -70.97,
            28.63,
            -70.93,
            30.8,
            -74.54,
            30.8,
            -74.46,
            43.11
         ]
      ],
      "rocade_fond_et1_phase00": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -85.17,
            43.06,
            -85.17,
            42.8,
            -85.19,
            42.81,
            -85.68,
            12.17,
            -86.08,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -70.83,
            11.36,
            -55.14,
            11.4,
            -16.46,
            11.26,
            -16.46,
            11.39,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.7,
            11.47,
            -2.04,
            28.83,
            -3.06,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.12,
            85.22,
            -75.12,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et1_phase01": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -85.17,
            43.06,
            -85.17,
            42.8,
            -85.19,
            42.81,
            -85.68,
            12.17,
            -86.08,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -70.83,
            11.36,
            -55.14,
            11.4,
            -16.46,
            11.26,
            -16.46,
            11.39,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.7,
            11.47,
            -2.04,
            28.83,
            -3.06,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.12,
            85.22,
            -75.12,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et1_phase02_03": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -85.17,
            43.06,
            -85.17,
            42.8,
            -85.19,
            42.81,
            -85.68,
            12.17,
            -86.08,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -70.83,
            11.36,
            -55.14,
            11.4,
            -16.46,
            11.26,
            -16.46,
            11.39,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.7,
            11.47,
            -2.04,
            28.83,
            -3.06,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.12,
            85.22,
            -75.12,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et1_phase04_05": [
         [
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.64,
            79.52,
            -110.58,
            79.52,
            -110.66,
            80.91,
            -110.64,
            80.91,
            -110.64,
            81.04,
            -75.12,
            81.04,
            -75.12,
            85.22,
            -75.03,
            85.22,
            -6.96,
            85.22,
            -6.96,
            85.23,
            -5.57,
            83.76,
            -2.94,
            50.55,
            -3.12,
            42.64,
            -3.44,
            42.65,
            -3.44,
            42.67,
            -3.62,
            42.65,
            -3.67,
            42.65,
            -3.67,
            42.53,
            -3.6,
            42.53,
            -3.59,
            42.41,
            -3.06,
            42.44,
            -2.04,
            28.83,
            -1.7,
            11.47,
            -1.71,
            10.72,
            -1.74,
            10.69,
            -1.73,
            9.79,
            -1.68,
            7.38,
            -1.76,
            6.53,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.95,
            8.69,
            -7.85,
            11.29,
            -9.11,
            11.32,
            -16.46,
            11.39,
            -16.46,
            11.26,
            -17.72,
            11.26,
            -47.9,
            11.4,
            -78.05,
            11.32,
            -84.84,
            11.27,
            -84.84,
            10.72,
            -86.08,
            10.74,
            -86.06,
            12.18,
            -85.68,
            12.17,
            -85.66,
            13.12,
            -85.19,
            42.81,
            -85.19,
            42.59,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -90.53,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.72,
            45.1,
            -108.83,
            45.09,
            -108.92,
            47.03,
            -109.01,
            47.17
         ]
      ],
      "rocade_fond_et1_phase06": [
         [
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.64,
            79.52,
            -110.58,
            79.52,
            -110.66,
            80.91,
            -110.64,
            80.91,
            -110.64,
            81.04,
            -75.12,
            81.04,
            -75.12,
            85.22,
            -75.03,
            85.22,
            -6.96,
            85.22,
            -6.96,
            85.23,
            -5.57,
            83.76,
            -2.94,
            50.55,
            -3.12,
            42.64,
            -3.44,
            42.65,
            -3.44,
            42.67,
            -3.62,
            42.65,
            -3.67,
            42.65,
            -3.67,
            42.53,
            -3.6,
            42.53,
            -3.59,
            42.41,
            -3.06,
            42.44,
            -2.04,
            28.83,
            -1.7,
            11.47,
            -1.71,
            10.72,
            -1.74,
            10.69,
            -1.73,
            9.79,
            -1.68,
            7.38,
            -1.76,
            6.53,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.95,
            8.69,
            -7.85,
            11.29,
            -9.11,
            11.32,
            -16.46,
            11.39,
            -16.46,
            11.26,
            -17.72,
            11.26,
            -47.9,
            11.4,
            -78.05,
            11.32,
            -84.84,
            11.27,
            -84.84,
            10.72,
            -86.08,
            10.74,
            -86.06,
            12.18,
            -85.68,
            12.17,
            -85.66,
            13.12,
            -85.19,
            42.81,
            -85.19,
            42.59,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -90.53,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.72,
            45.1,
            -108.83,
            45.09,
            -108.92,
            47.03,
            -109.01,
            47.17
         ]
      ],
      "rocade_fond_et2_phase00": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -45.63,
            43.11,
            -45.63,
            29.66,
            -57.88,
            29.52,
            -57.88,
            29.45,
            -61.14,
            29.45,
            -61.14,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -68.79,
            29.61,
            -79.88,
            29.85,
            -79.89,
            29.83,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.53,
            -79.88,
            11.7,
            -27.77,
            11.66,
            -7.85,
            11.5,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.72,
            13.5,
            -2.02,
            28.83,
            -2.04,
            28.83,
            -2.1,
            28.83,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et2_phase01": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -45.63,
            43.11,
            -45.63,
            29.66,
            -57.88,
            29.52,
            -57.88,
            29.45,
            -61.14,
            29.45,
            -61.14,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -68.79,
            29.61,
            -79.88,
            29.85,
            -79.89,
            29.83,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.53,
            -79.88,
            11.7,
            -27.77,
            11.66,
            -7.85,
            11.5,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.72,
            13.5,
            -2.02,
            28.83,
            -2.04,
            28.83,
            -2.1,
            28.83,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et2_phase02_03": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -109.94,
            65.9,
            -109.93,
            65.8,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.53,
            58.37,
            -109.52,
            58.23,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -45.63,
            43.11,
            -45.63,
            29.66,
            -57.88,
            29.52,
            -57.88,
            29.45,
            -61.14,
            29.45,
            -61.14,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -68.79,
            29.61,
            -79.88,
            29.85,
            -79.89,
            29.83,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.53,
            -79.88,
            11.7,
            -27.77,
            11.66,
            -7.85,
            11.5,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.34,
            3.6,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.72,
            13.5,
            -2.02,
            28.83,
            -2.04,
            28.83,
            -2.1,
            28.83,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.63,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            61.01,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.62,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.23,
            -6.98,
            85.22,
            -18.99,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.64,
            81.04,
            -110.64,
            80.91,
            -110.66,
            80.91,
            -110.58,
            79.52,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et2_phase04_05": [
         [
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -108.98,
            48.18,
            -108.89,
            48.18,
            -109.02,
            50.67,
            -109.11,
            50.66,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.18,
            51.96,
            -109.09,
            51.97,
            -109.23,
            54.45,
            -109.32,
            54.44,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.39,
            55.74,
            -109.3,
            55.75,
            -109.43,
            58.23,
            -109.52,
            58.22,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.59,
            59.52,
            -109.5,
            59.53,
            -109.63,
            61.99,
            -109.73,
            61.98,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.8,
            63.28,
            -109.7,
            63.29,
            -109.84,
            65.81,
            -109.93,
            65.8,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110,
            67,
            -109.91,
            67.01,
            -110.04,
            69.39,
            -110.11,
            69.39,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.17,
            70.59,
            -110.1,
            70.59,
            -110.23,
            72.99,
            -110.3,
            72.98,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.36,
            74.18,
            -110.29,
            74.19,
            -110.43,
            76.6,
            -110.49,
            76.6,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.55,
            77.8,
            -110.49,
            77.8,
            -110.65,
            80.91,
            -110.65,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.1,
            28.83,
            -2.02,
            28.83,
            -1.69,
            12.32,
            -1.71,
            8.63,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.84,
            11.5,
            -9.12,
            11.51,
            -31.85,
            11.67,
            -73.7,
            11.7,
            -79.88,
            11.7,
            -85.68,
            11.53,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -73,
            29.68,
            -73,
            32.63,
            -74.84,
            32.63,
            -74.8,
            41.62,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.82,
            46.88,
            -108.91,
            46.88,
            -108.92,
            47.03,
            -109.01,
            47.17
         ]
      ],
      "rocade_fond_et2_phase06": [
         [
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -108.98,
            48.18,
            -108.89,
            48.18,
            -109.02,
            50.67,
            -109.11,
            50.66,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.18,
            51.96,
            -109.09,
            51.97,
            -109.23,
            54.45,
            -109.32,
            54.44,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.39,
            55.74,
            -109.3,
            55.75,
            -109.43,
            58.23,
            -109.52,
            58.22,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.59,
            59.52,
            -109.5,
            59.53,
            -109.63,
            61.99,
            -109.73,
            61.98,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.8,
            63.28,
            -109.7,
            63.29,
            -109.84,
            65.81,
            -109.93,
            65.8,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110,
            67,
            -109.91,
            67.01,
            -110.04,
            69.39,
            -110.11,
            69.39,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.17,
            70.59,
            -110.1,
            70.59,
            -110.23,
            72.99,
            -110.3,
            72.98,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.36,
            74.18,
            -110.29,
            74.19,
            -110.43,
            76.6,
            -110.49,
            76.6,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.55,
            77.8,
            -110.49,
            77.8,
            -110.65,
            80.91,
            -110.65,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.1,
            28.83,
            -2.02,
            28.83,
            -1.69,
            12.32,
            -1.71,
            8.63,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.84,
            11.5,
            -9.12,
            11.51,
            -31.85,
            11.67,
            -73.7,
            11.7,
            -79.88,
            11.7,
            -85.68,
            11.53,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -73,
            29.68,
            -73,
            32.63,
            -74.84,
            32.63,
            -74.8,
            41.62,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.82,
            46.88,
            -108.91,
            46.88,
            -108.92,
            47.03,
            -109.01,
            47.17
         ]
      ],
      "rocade_fond_et3_phase00": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.06,
            66.82,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -110.01,
            65.97,
            -109.94,
            65.9,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.65,
            59.3,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.6,
            58.43,
            -109.53,
            58.37,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.39,
            54.65,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.24,
            51.75,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.19,
            50.87,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.03,
            47.96,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.98,
            47.09,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.83,
            44.18,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.61,
            47.29,
            -45.45,
            47.29,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -8.34,
            3.6,
            -6.12,
            3.45,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.7,
            13.5,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.65,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            60.99,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.61,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.22,
            -6.98,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.65,
            81.04,
            -110.65,
            80.91,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et3_phase01": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.06,
            66.82,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -110.01,
            65.97,
            -109.94,
            65.9,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.65,
            59.3,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.6,
            58.43,
            -109.53,
            58.37,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.39,
            54.65,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.24,
            51.75,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.19,
            50.87,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.03,
            47.96,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.98,
            47.09,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.83,
            44.18,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.61,
            47.29,
            -45.45,
            47.29,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -8.34,
            3.6,
            -6.12,
            3.45,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.7,
            13.5,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.65,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            60.99,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.61,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.22,
            -6.98,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.65,
            81.04,
            -110.65,
            80.91,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et3_phase02_03": [
         [
            -110.55,
            77.8,
            -110.55,
            77.7,
            -110.63,
            77.52,
            -110.8,
            77.51,
            -110.88,
            77.18,
            -110.76,
            76.86,
            -110.59,
            76.87,
            -110.57,
            76.77,
            -110.49,
            76.7,
            -110.36,
            74.08,
            -110.44,
            73.9,
            -110.61,
            73.89,
            -110.69,
            73.56,
            -110.57,
            73.24,
            -110.4,
            73.25,
            -110.3,
            73.08,
            -110.3,
            72.98,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.17,
            70.59,
            -110.17,
            70.49,
            -110.25,
            70.31,
            -110.42,
            70.3,
            -110.51,
            69.97,
            -110.38,
            69.65,
            -110.21,
            69.66,
            -110.12,
            69.49,
            -110.11,
            69.39,
            -109.99,
            66.9,
            -110.06,
            66.82,
            -110.07,
            66.72,
            -110.24,
            66.71,
            -110.33,
            66.38,
            -110.2,
            66.06,
            -110.04,
            66.07,
            -110.01,
            65.97,
            -109.94,
            65.9,
            -109.79,
            63.13,
            -109.87,
            62.98,
            -110.02,
            62.97,
            -110.12,
            62.74,
            -110.1,
            62.49,
            -109.98,
            62.27,
            -109.83,
            62.28,
            -109.73,
            62.13,
            -109.73,
            61.98,
            -109.58,
            59.37,
            -109.65,
            59.3,
            -109.67,
            59.22,
            -109.81,
            59.21,
            -109.91,
            58.98,
            -109.9,
            58.73,
            -109.78,
            58.51,
            -109.63,
            58.52,
            -109.6,
            58.43,
            -109.53,
            58.37,
            -109.38,
            55.6,
            -109.46,
            55.44,
            -109.61,
            55.43,
            -109.71,
            55.2,
            -109.7,
            54.95,
            -109.57,
            54.73,
            -109.42,
            54.74,
            -109.39,
            54.65,
            -109.33,
            54.59,
            -109.18,
            51.81,
            -109.24,
            51.75,
            -109.26,
            51.66,
            -109.41,
            51.65,
            -109.5,
            51.42,
            -109.49,
            51.17,
            -109.37,
            50.95,
            -109.22,
            50.96,
            -109.19,
            50.87,
            -109.12,
            50.81,
            -108.97,
            48.03,
            -109.03,
            47.96,
            -109.05,
            47.87,
            -109.2,
            47.86,
            -109.3,
            47.63,
            -109.29,
            47.38,
            -109.16,
            47.17,
            -109.01,
            47.17,
            -108.98,
            47.09,
            -108.92,
            47.03,
            -108.77,
            44.24,
            -108.83,
            44.18,
            -108.85,
            44.09,
            -109.12,
            44.07,
            -109.06,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.61,
            47.29,
            -45.45,
            47.29,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -8.34,
            3.6,
            -6.12,
            3.45,
            -2.08,
            3.16,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.7,
            13.5,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.54,
            44.08,
            -3.34,
            44.13,
            -3.35,
            44.22,
            -3.55,
            44.24,
            -3.65,
            45.47,
            -3.45,
            45.52,
            -3.46,
            45.61,
            -3.66,
            45.63,
            -3.76,
            46.89,
            -3.57,
            46.94,
            -3.57,
            47.03,
            -3.77,
            47.05,
            -3.87,
            48.28,
            -3.68,
            48.33,
            -3.68,
            48.42,
            -3.89,
            48.44,
            -3.98,
            49.7,
            -3.79,
            49.75,
            -3.8,
            49.84,
            -4,
            49.86,
            -4.1,
            51.09,
            -3.9,
            51.14,
            -3.91,
            51.23,
            -4.11,
            51.25,
            -4.21,
            52.49,
            -4.01,
            52.54,
            -4.02,
            52.63,
            -4.22,
            52.65,
            -4.32,
            53.89,
            -4.12,
            53.94,
            -4.13,
            54.03,
            -4.33,
            54.04,
            -4.43,
            55.28,
            -4.23,
            55.33,
            -4.24,
            55.42,
            -4.44,
            55.44,
            -4.54,
            56.68,
            -4.34,
            56.73,
            -4.35,
            56.82,
            -4.55,
            56.84,
            -4.65,
            58.06,
            -4.45,
            58.11,
            -4.46,
            58.2,
            -4.66,
            58.22,
            -4.76,
            59.45,
            -4.56,
            59.5,
            -4.57,
            59.59,
            -4.77,
            59.61,
            -4.87,
            60.83,
            -4.67,
            60.88,
            -4.68,
            60.97,
            -4.88,
            60.99,
            -4.98,
            62.24,
            -4.78,
            62.29,
            -4.79,
            62.38,
            -4.99,
            62.4,
            -5.09,
            63.62,
            -4.89,
            63.68,
            -4.9,
            63.76,
            -5.1,
            63.78,
            -5.2,
            65.01,
            -5,
            65.06,
            -5.01,
            65.15,
            -5.21,
            65.16,
            -5.31,
            66.42,
            -5.11,
            66.47,
            -5.12,
            66.56,
            -5.32,
            66.58,
            -5.42,
            67.8,
            -5.22,
            67.85,
            -5.23,
            67.94,
            -5.43,
            67.96,
            -5.53,
            69.19,
            -5.33,
            69.24,
            -5.34,
            69.33,
            -5.54,
            69.35,
            -5.64,
            70.59,
            -5.45,
            70.64,
            -5.45,
            70.73,
            -5.65,
            70.75,
            -5.75,
            71.98,
            -5.56,
            72.03,
            -5.56,
            72.12,
            -5.77,
            72.14,
            -5.86,
            73.36,
            -5.67,
            73.41,
            -5.67,
            73.5,
            -5.87,
            73.52,
            -5.97,
            74.75,
            -5.78,
            74.81,
            -5.78,
            74.89,
            -5.99,
            74.91,
            -6.08,
            76.15,
            -5.89,
            76.2,
            -5.89,
            76.29,
            -6.1,
            76.31,
            -6.19,
            77.55,
            -6,
            77.6,
            -6,
            77.69,
            -6.21,
            77.71,
            -6.3,
            78.94,
            -6.11,
            78.99,
            -6.12,
            79.08,
            -6.32,
            79.1,
            -6.42,
            80.34,
            -6.22,
            80.39,
            -6.23,
            80.48,
            -6.43,
            80.5,
            -6.47,
            81.01,
            -6.27,
            81.07,
            -6.28,
            81.16,
            -6.48,
            81.17,
            -6.52,
            81.69,
            -6.33,
            81.74,
            -6.33,
            81.83,
            -6.54,
            81.85,
            -6.58,
            82.39,
            -6.38,
            82.44,
            -6.39,
            82.53,
            -6.59,
            82.55,
            -6.63,
            83.07,
            -6.44,
            83.12,
            -6.44,
            83.21,
            -6.65,
            83.23,
            -6.69,
            83.79,
            -6.49,
            83.84,
            -6.5,
            83.95,
            -6.7,
            83.95,
            -6.74,
            84.45,
            -6.55,
            84.5,
            -6.55,
            84.59,
            -6.75,
            84.61,
            -6.79,
            85.12,
            -6.6,
            85.17,
            -6.61,
            85.26,
            -6.81,
            85.28,
            -6.89,
            85.27,
            -6.89,
            85.22,
            -6.98,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.65,
            81.04,
            -110.65,
            80.91,
            -110.49,
            77.8,
            -110.55,
            77.8
         ]
      ],
      "rocade_fond_et3_phase04_05": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.47,
            -35.91,
            29.47,
            -35.91,
            25.86,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -24.95,
            28.6,
            -27.27,
            28.63,
            -27.27,
            31.42,
            -18.15,
            31.29,
            -18.15,
            35.9,
            -18.21,
            35.9,
            -18.21,
            35.92,
            -27.2,
            36.02,
            -27.2,
            36.11,
            -29.29,
            36.11,
            -29.29,
            40.09,
            -26.91,
            40.09,
            -26.91,
            40.13,
            -20.42,
            40,
            -20.46,
            37.72,
            -18.15,
            37.68,
            -18.15,
            41.85,
            -18.14,
            42.16,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.82,
            46.88,
            -108.91,
            46.88,
            -108.92,
            47.03,
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -108.98,
            48.18,
            -108.89,
            48.18,
            -109.02,
            50.67,
            -109.11,
            50.66,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.18,
            51.96,
            -109.09,
            51.97,
            -109.23,
            54.45,
            -109.32,
            54.44,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.39,
            55.74,
            -109.3,
            55.75,
            -109.43,
            58.23,
            -109.52,
            58.22,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.59,
            59.52,
            -109.5,
            59.53,
            -109.63,
            61.99,
            -109.73,
            61.98,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.8,
            63.28,
            -109.7,
            63.29,
            -109.84,
            65.81,
            -109.93,
            65.8,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110,
            67,
            -109.91,
            67.01,
            -110.04,
            69.39,
            -110.11,
            69.39,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.17,
            70.59,
            -110.1,
            70.59,
            -110.23,
            72.99,
            -110.3,
            72.98,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.36,
            74.18,
            -110.29,
            74.19,
            -110.43,
            76.6,
            -110.49,
            76.6,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.55,
            77.8,
            -110.49,
            77.8,
            -110.65,
            80.91,
            -110.65,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.1,
            28.83,
            -2.02,
            28.83,
            -1.69,
            12.32,
            -1.71,
            8.63,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.84,
            11.5,
            -9.12,
            11.51,
            -31.85,
            11.67,
            -73.7,
            11.7,
            -79.88,
            11.7,
            -85.68,
            11.53,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.14,
            24.2,
            -61.14,
            29.45,
            -45.97,
            29.67,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et3_phase06": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.47,
            -35.91,
            29.47,
            -35.91,
            25.86,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -24.95,
            28.6,
            -27.27,
            28.63,
            -27.27,
            31.42,
            -18.15,
            31.29,
            -18.15,
            35.9,
            -18.21,
            35.9,
            -18.21,
            35.92,
            -27.2,
            36.02,
            -27.2,
            36.11,
            -29.29,
            36.11,
            -29.29,
            40.09,
            -26.91,
            40.09,
            -26.91,
            40.13,
            -20.42,
            40,
            -20.46,
            37.72,
            -18.15,
            37.68,
            -18.15,
            41.85,
            -18.14,
            42.16,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -109.06,
            43.06,
            -109.12,
            44.07,
            -108.85,
            44.09,
            -108.77,
            44.24,
            -108.77,
            44.39,
            -108.68,
            44.4,
            -108.82,
            46.88,
            -108.91,
            46.88,
            -108.92,
            47.03,
            -109.01,
            47.17,
            -109.16,
            47.17,
            -109.29,
            47.38,
            -109.3,
            47.63,
            -109.2,
            47.86,
            -109.05,
            47.87,
            -108.97,
            48.03,
            -108.98,
            48.18,
            -108.89,
            48.18,
            -109.02,
            50.67,
            -109.11,
            50.66,
            -109.12,
            50.81,
            -109.22,
            50.96,
            -109.37,
            50.95,
            -109.49,
            51.17,
            -109.5,
            51.42,
            -109.41,
            51.65,
            -109.26,
            51.66,
            -109.18,
            51.81,
            -109.18,
            51.96,
            -109.09,
            51.97,
            -109.23,
            54.45,
            -109.32,
            54.44,
            -109.33,
            54.59,
            -109.42,
            54.74,
            -109.57,
            54.73,
            -109.7,
            54.95,
            -109.71,
            55.2,
            -109.61,
            55.43,
            -109.46,
            55.44,
            -109.38,
            55.59,
            -109.39,
            55.74,
            -109.3,
            55.75,
            -109.43,
            58.23,
            -109.52,
            58.22,
            -109.53,
            58.37,
            -109.63,
            58.52,
            -109.78,
            58.51,
            -109.9,
            58.73,
            -109.91,
            58.98,
            -109.81,
            59.21,
            -109.67,
            59.22,
            -109.58,
            59.37,
            -109.59,
            59.52,
            -109.5,
            59.53,
            -109.63,
            61.99,
            -109.73,
            61.98,
            -109.73,
            62.13,
            -109.83,
            62.28,
            -109.98,
            62.27,
            -110.1,
            62.49,
            -110.12,
            62.74,
            -110.02,
            62.97,
            -109.87,
            62.98,
            -109.79,
            63.13,
            -109.8,
            63.28,
            -109.7,
            63.29,
            -109.84,
            65.81,
            -109.93,
            65.8,
            -109.94,
            65.9,
            -110.04,
            66.07,
            -110.2,
            66.06,
            -110.33,
            66.38,
            -110.24,
            66.71,
            -110.07,
            66.72,
            -109.99,
            66.9,
            -110,
            67,
            -109.91,
            67.01,
            -110.04,
            69.39,
            -110.11,
            69.39,
            -110.12,
            69.49,
            -110.21,
            69.66,
            -110.38,
            69.65,
            -110.51,
            69.97,
            -110.42,
            70.3,
            -110.25,
            70.31,
            -110.17,
            70.49,
            -110.17,
            70.59,
            -110.1,
            70.59,
            -110.23,
            72.99,
            -110.3,
            72.98,
            -110.3,
            73.08,
            -110.4,
            73.25,
            -110.57,
            73.24,
            -110.69,
            73.56,
            -110.61,
            73.89,
            -110.44,
            73.9,
            -110.36,
            74.08,
            -110.36,
            74.18,
            -110.29,
            74.19,
            -110.43,
            76.6,
            -110.49,
            76.6,
            -110.49,
            76.7,
            -110.59,
            76.87,
            -110.76,
            76.86,
            -110.88,
            77.18,
            -110.8,
            77.51,
            -110.63,
            77.52,
            -110.55,
            77.7,
            -110.55,
            77.8,
            -110.49,
            77.8,
            -110.65,
            80.91,
            -110.65,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.1,
            28.83,
            -2.02,
            28.83,
            -1.69,
            12.32,
            -1.71,
            8.63,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.84,
            11.5,
            -9.12,
            11.51,
            -31.85,
            11.67,
            -73.7,
            11.7,
            -79.88,
            11.7,
            -85.68,
            11.53,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.14,
            24.2,
            -61.14,
            29.45,
            -45.97,
            29.67,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et4_phase00": [
         [
            -7.85,
            11.29,
            -7.97,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.71,
            10.7,
            -1.66,
            11.47,
            -1.68,
            12.54,
            -1.34,
            12.55,
            -1.34,
            12.68,
            -1.44,
            13.06,
            -1.48,
            13.05,
            -1.54,
            13.31,
            -1.62,
            13.31,
            -1.65,
            13.43,
            -1.7,
            13.43,
            -1.7,
            13.5,
            -1.98,
            27.17,
            -1.93,
            27.17,
            -1.89,
            27.3,
            -1.82,
            27.31,
            -1.77,
            27.57,
            -1.73,
            27.58,
            -1.64,
            27.95,
            -1.65,
            28.11,
            -2,
            28.1,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.72,
            42.39,
            -3.73,
            42.54,
            -6.62,
            42.61,
            -6.75,
            42.61,
            -6.75,
            42.73,
            -9.47,
            42.78,
            -12.92,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.85,
            81.05,
            -110.82,
            80.47,
            -110.73,
            80.42,
            -110.72,
            80.28,
            -110.63,
            80.28,
            -110.58,
            79.52,
            -110.48,
            77.67,
            -110.56,
            77.67,
            -110.55,
            77.53,
            -110.64,
            77.47,
            -110.61,
            76.89,
            -110.52,
            76.84,
            -110.51,
            76.7,
            -110.43,
            76.7,
            -110.34,
            75.05,
            -110.29,
            74.06,
            -110.37,
            74.05,
            -110.36,
            73.91,
            -110.45,
            73.86,
            -110.42,
            73.27,
            -110.33,
            73.22,
            -110.32,
            73.08,
            -110.24,
            73.09,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.09,
            70.46,
            -110.18,
            70.46,
            -110.18,
            70.32,
            -110.26,
            70.26,
            -110.23,
            69.68,
            -110.14,
            69.63,
            -110.13,
            69.49,
            -110.12,
            69.49,
            -110.04,
            69.49,
            -109.9,
            66.91,
            -109.99,
            66.9,
            -110.01,
            66.9,
            -110,
            66.76,
            -110.08,
            66.7,
            -110.05,
            66.12,
            -109.96,
            66.07,
            -109.95,
            65.93,
            -109.85,
            65.94,
            -109.7,
            63.12,
            -109.8,
            63.12,
            -109.79,
            62.98,
            -109.88,
            62.92,
            -109.85,
            62.33,
            -109.76,
            62.29,
            -109.75,
            62.15,
            -109.64,
            62.15,
            -109.6,
            61.38,
            -109.49,
            59.35,
            -109.6,
            59.34,
            -109.59,
            59.2,
            -109.67,
            59.15,
            -109.64,
            58.56,
            -109.55,
            58.51,
            -109.55,
            58.37,
            -109.44,
            58.38,
            -109.29,
            55.58,
            -109.39,
            55.57,
            -109.39,
            55.43,
            -109.47,
            55.38,
            -109.44,
            54.79,
            -109.35,
            54.74,
            -109.34,
            54.6,
            -109.24,
            54.61,
            -109.09,
            51.83,
            -109.19,
            51.83,
            -109.18,
            51.69,
            -109.26,
            51.63,
            -109.23,
            51.05,
            -109.14,
            51,
            -109.14,
            50.86,
            -109.03,
            50.86,
            -108.88,
            48,
            -108.98,
            48,
            -108.98,
            47.86,
            -109.06,
            47.8,
            -109.03,
            47.22,
            -108.94,
            47.17,
            -108.93,
            47.03,
            -108.83,
            47.03,
            -108.67,
            44.23,
            -108.79,
            44.22,
            -108.79,
            44.11,
            -108.87,
            44.09,
            -108.84,
            43.39,
            -108.8,
            43.37,
            -108.78,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.59,
            50.8,
            -45.45,
            50.8,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29
         ]
      ],
      "rocade_fond_et4_phase01": [
         [
            -7.85,
            11.29,
            -7.97,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.71,
            10.7,
            -1.66,
            11.47,
            -1.68,
            12.54,
            -1.34,
            12.55,
            -1.34,
            12.68,
            -1.44,
            13.06,
            -1.48,
            13.05,
            -1.54,
            13.31,
            -1.62,
            13.31,
            -1.65,
            13.43,
            -1.7,
            13.43,
            -1.7,
            13.5,
            -1.98,
            27.17,
            -1.93,
            27.17,
            -1.89,
            27.3,
            -1.82,
            27.31,
            -1.77,
            27.57,
            -1.73,
            27.58,
            -1.64,
            27.95,
            -1.65,
            28.11,
            -2,
            28.1,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.72,
            42.39,
            -3.73,
            42.54,
            -6.62,
            42.61,
            -6.75,
            42.61,
            -6.75,
            42.73,
            -9.47,
            42.78,
            -12.92,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.85,
            81.05,
            -110.82,
            80.47,
            -110.73,
            80.42,
            -110.72,
            80.28,
            -110.63,
            80.28,
            -110.58,
            79.52,
            -110.48,
            77.67,
            -110.56,
            77.67,
            -110.55,
            77.53,
            -110.64,
            77.47,
            -110.61,
            76.89,
            -110.52,
            76.84,
            -110.51,
            76.7,
            -110.43,
            76.7,
            -110.34,
            75.05,
            -110.29,
            74.06,
            -110.37,
            74.05,
            -110.36,
            73.91,
            -110.45,
            73.86,
            -110.42,
            73.27,
            -110.33,
            73.22,
            -110.32,
            73.08,
            -110.24,
            73.09,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.09,
            70.46,
            -110.18,
            70.46,
            -110.18,
            70.32,
            -110.26,
            70.26,
            -110.23,
            69.68,
            -110.14,
            69.63,
            -110.13,
            69.49,
            -110.12,
            69.49,
            -110.04,
            69.49,
            -109.9,
            66.91,
            -109.99,
            66.9,
            -110.01,
            66.9,
            -110,
            66.76,
            -110.08,
            66.7,
            -110.05,
            66.12,
            -109.96,
            66.07,
            -109.95,
            65.93,
            -109.85,
            65.94,
            -109.7,
            63.12,
            -109.8,
            63.12,
            -109.79,
            62.98,
            -109.88,
            62.92,
            -109.85,
            62.33,
            -109.76,
            62.29,
            -109.75,
            62.15,
            -109.64,
            62.15,
            -109.6,
            61.38,
            -109.49,
            59.35,
            -109.6,
            59.34,
            -109.59,
            59.2,
            -109.67,
            59.15,
            -109.64,
            58.56,
            -109.55,
            58.51,
            -109.55,
            58.37,
            -109.44,
            58.38,
            -109.29,
            55.58,
            -109.39,
            55.57,
            -109.39,
            55.43,
            -109.47,
            55.38,
            -109.44,
            54.79,
            -109.35,
            54.74,
            -109.34,
            54.6,
            -109.24,
            54.61,
            -109.09,
            51.83,
            -109.19,
            51.83,
            -109.18,
            51.69,
            -109.26,
            51.63,
            -109.23,
            51.05,
            -109.14,
            51,
            -109.14,
            50.86,
            -109.03,
            50.86,
            -108.88,
            48,
            -108.98,
            48,
            -108.98,
            47.86,
            -109.06,
            47.8,
            -109.03,
            47.22,
            -108.94,
            47.17,
            -108.93,
            47.03,
            -108.83,
            47.03,
            -108.67,
            44.23,
            -108.79,
            44.22,
            -108.79,
            44.11,
            -108.87,
            44.09,
            -108.84,
            43.39,
            -108.8,
            43.37,
            -108.78,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.59,
            50.8,
            -45.45,
            50.8,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29
         ]
      ],
      "rocade_fond_et4_phase02_03": [
         [
            -7.85,
            11.29,
            -7.97,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.04,
            7.8,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.71,
            10.7,
            -1.66,
            11.47,
            -1.68,
            12.54,
            -1.34,
            12.55,
            -1.34,
            12.68,
            -1.44,
            13.06,
            -1.48,
            13.05,
            -1.54,
            13.31,
            -1.62,
            13.31,
            -1.65,
            13.43,
            -1.7,
            13.43,
            -1.7,
            13.5,
            -1.98,
            27.17,
            -1.93,
            27.17,
            -1.89,
            27.3,
            -1.82,
            27.31,
            -1.77,
            27.57,
            -1.73,
            27.58,
            -1.64,
            27.95,
            -1.65,
            28.11,
            -2,
            28.1,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -2.5,
            34.2,
            -3.12,
            42.44,
            -3.41,
            42.42,
            -3.72,
            42.39,
            -3.73,
            42.54,
            -6.62,
            42.61,
            -6.75,
            42.61,
            -6.75,
            42.73,
            -9.47,
            42.78,
            -12.92,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.85,
            81.05,
            -110.82,
            80.47,
            -110.73,
            80.42,
            -110.72,
            80.28,
            -110.63,
            80.28,
            -110.58,
            79.52,
            -110.48,
            77.67,
            -110.56,
            77.67,
            -110.55,
            77.53,
            -110.64,
            77.47,
            -110.61,
            76.89,
            -110.52,
            76.84,
            -110.51,
            76.7,
            -110.43,
            76.7,
            -110.34,
            75.05,
            -110.29,
            74.06,
            -110.37,
            74.05,
            -110.36,
            73.91,
            -110.45,
            73.86,
            -110.42,
            73.27,
            -110.33,
            73.22,
            -110.32,
            73.08,
            -110.24,
            73.09,
            -110.23,
            72.99,
            -110.19,
            72.34,
            -110.29,
            72.33,
            -110.28,
            72.13,
            -110.18,
            72.14,
            -110.15,
            71.47,
            -110.24,
            71.46,
            -110.23,
            71.27,
            -110.14,
            71.27,
            -110.1,
            70.59,
            -110.09,
            70.46,
            -110.18,
            70.46,
            -110.18,
            70.32,
            -110.26,
            70.26,
            -110.23,
            69.68,
            -110.14,
            69.63,
            -110.13,
            69.49,
            -110.12,
            69.49,
            -110.04,
            69.49,
            -109.9,
            66.91,
            -109.99,
            66.9,
            -110.01,
            66.9,
            -110,
            66.76,
            -110.08,
            66.7,
            -110.05,
            66.12,
            -109.96,
            66.07,
            -109.95,
            65.93,
            -109.85,
            65.94,
            -109.7,
            63.12,
            -109.8,
            63.12,
            -109.79,
            62.98,
            -109.88,
            62.92,
            -109.85,
            62.33,
            -109.76,
            62.29,
            -109.75,
            62.15,
            -109.64,
            62.15,
            -109.6,
            61.38,
            -109.49,
            59.35,
            -109.6,
            59.34,
            -109.59,
            59.2,
            -109.67,
            59.15,
            -109.64,
            58.56,
            -109.55,
            58.51,
            -109.55,
            58.37,
            -109.44,
            58.38,
            -109.29,
            55.58,
            -109.39,
            55.57,
            -109.39,
            55.43,
            -109.47,
            55.38,
            -109.44,
            54.79,
            -109.35,
            54.74,
            -109.34,
            54.6,
            -109.24,
            54.61,
            -109.09,
            51.83,
            -109.19,
            51.83,
            -109.18,
            51.69,
            -109.26,
            51.63,
            -109.23,
            51.05,
            -109.14,
            51,
            -109.14,
            50.86,
            -109.03,
            50.86,
            -108.88,
            48,
            -108.98,
            48,
            -108.98,
            47.86,
            -109.06,
            47.8,
            -109.03,
            47.22,
            -108.94,
            47.17,
            -108.93,
            47.03,
            -108.83,
            47.03,
            -108.67,
            44.23,
            -108.79,
            44.22,
            -108.79,
            44.11,
            -108.87,
            44.09,
            -108.84,
            43.39,
            -108.8,
            43.37,
            -108.78,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.59,
            50.8,
            -45.45,
            50.8,
            -45.45,
            43.12,
            -45.14,
            43.12,
            -45.14,
            29.67,
            -45.63,
            29.66,
            -47.65,
            29.64,
            -47.65,
            29.65,
            -49.45,
            29.62,
            -61.14,
            29.45,
            -61.12,
            24.2,
            -67.67,
            24.2,
            -67.67,
            29.45,
            -67.67,
            29.56,
            -71.01,
            29.64,
            -81.4,
            29.86,
            -85.39,
            29.82,
            -85.68,
            11.68,
            -79.88,
            11.85,
            -26.36,
            11.83,
            -7.84,
            11.56,
            -7.85,
            11.29
         ]
      ],
      "rocade_fond_et4_phase04_05": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.47,
            -35.91,
            29.47,
            -35.91,
            25.86,
            -35.53,
            25.84,
            -35.19,
            25.67,
            -34.96,
            25.36,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -24.95,
            28.6,
            -27.27,
            28.63,
            -27.27,
            31.42,
            -18.15,
            31.29,
            -18.15,
            35.9,
            -18.21,
            35.9,
            -18.21,
            35.92,
            -27.2,
            36.02,
            -27.2,
            36.11,
            -29.29,
            36.11,
            -29.29,
            40.09,
            -26.91,
            40.09,
            -26.91,
            40.13,
            -20.42,
            40,
            -20.46,
            37.73,
            -18.45,
            37.69,
            -18.15,
            37.68,
            -18.15,
            41.85,
            -18.15,
            42.16,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -108.78,
            43.06,
            -108.8,
            43.37,
            -108.84,
            43.39,
            -108.87,
            44.09,
            -108.79,
            44.11,
            -108.79,
            44.22,
            -108.67,
            44.23,
            -108.83,
            47.03,
            -108.93,
            47.03,
            -108.94,
            47.17,
            -109.03,
            47.22,
            -109.15,
            47.5,
            -109.06,
            47.8,
            -108.98,
            47.86,
            -108.98,
            48,
            -108.88,
            48,
            -109.03,
            50.86,
            -109.14,
            50.86,
            -109.14,
            51,
            -109.23,
            51.05,
            -109.26,
            51.63,
            -109.18,
            51.69,
            -109.19,
            51.83,
            -109.08,
            51.83,
            -109.23,
            54.58,
            -109.24,
            54.61,
            -109.34,
            54.6,
            -109.35,
            54.74,
            -109.44,
            54.79,
            -109.47,
            55.38,
            -109.39,
            55.43,
            -109.39,
            55.57,
            -109.29,
            55.58,
            -109.44,
            58.38,
            -109.55,
            58.37,
            -109.55,
            58.51,
            -109.64,
            58.56,
            -109.67,
            59.15,
            -109.59,
            59.2,
            -109.6,
            59.34,
            -109.49,
            59.35,
            -109.64,
            62.14,
            -109.64,
            62.15,
            -109.75,
            62.15,
            -109.76,
            62.29,
            -109.85,
            62.33,
            -109.88,
            62.92,
            -109.79,
            62.98,
            -109.8,
            63.12,
            -109.7,
            63.12,
            -109.85,
            65.94,
            -109.95,
            65.93,
            -109.96,
            66.07,
            -110.05,
            66.12,
            -110.08,
            66.7,
            -110,
            66.76,
            -110.01,
            66.9,
            -109.9,
            66.91,
            -110.04,
            69.49,
            -110.13,
            69.49,
            -110.14,
            69.63,
            -110.23,
            69.68,
            -110.26,
            70.26,
            -110.18,
            70.32,
            -110.18,
            70.46,
            -110.09,
            70.46,
            -110.24,
            73.09,
            -110.32,
            73.08,
            -110.33,
            73.22,
            -110.42,
            73.27,
            -110.45,
            73.86,
            -110.36,
            73.91,
            -110.37,
            74.05,
            -110.29,
            74.06,
            -110.43,
            76.7,
            -110.51,
            76.7,
            -110.52,
            76.84,
            -110.61,
            76.89,
            -110.64,
            77.47,
            -110.55,
            77.53,
            -110.56,
            77.67,
            -110.48,
            77.67,
            -110.63,
            80.28,
            -110.72,
            80.28,
            -110.73,
            80.42,
            -110.82,
            80.47,
            -110.85,
            81.05,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.11,
            28.92,
            -2.01,
            28.92,
            -1.69,
            12.32,
            -1.66,
            11.47,
            -1.71,
            10.68,
            -1.7,
            9.8,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.87,
            10.89,
            -11.73,
            10.89,
            -20.73,
            11.09,
            -45.87,
            11.06,
            -67.3,
            10.93,
            -85.69,
            10.93,
            -85.69,
            12.09,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.12,
            24.2,
            -61.12,
            29.41,
            -45.97,
            29.63,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et4_phase06": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.47,
            -35.91,
            29.47,
            -35.91,
            25.86,
            -35.53,
            25.84,
            -35.19,
            25.67,
            -34.96,
            25.36,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -24.95,
            28.6,
            -27.27,
            28.63,
            -27.27,
            31.42,
            -18.15,
            31.29,
            -18.15,
            35.9,
            -18.21,
            35.9,
            -18.21,
            35.92,
            -27.2,
            36.02,
            -27.2,
            36.11,
            -29.29,
            36.11,
            -29.29,
            40.09,
            -26.91,
            40.09,
            -26.91,
            40.13,
            -20.42,
            40,
            -20.46,
            37.73,
            -18.45,
            37.69,
            -18.15,
            37.68,
            -18.15,
            41.85,
            -18.15,
            42.16,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -108.78,
            43.06,
            -108.8,
            43.37,
            -108.84,
            43.39,
            -108.87,
            44.09,
            -108.79,
            44.11,
            -108.79,
            44.22,
            -108.67,
            44.23,
            -108.83,
            47.03,
            -108.93,
            47.03,
            -108.94,
            47.17,
            -109.03,
            47.22,
            -109.15,
            47.5,
            -109.06,
            47.8,
            -108.98,
            47.86,
            -108.98,
            48,
            -108.88,
            48,
            -109.03,
            50.86,
            -109.14,
            50.86,
            -109.14,
            51,
            -109.23,
            51.05,
            -109.26,
            51.63,
            -109.18,
            51.69,
            -109.19,
            51.83,
            -109.08,
            51.83,
            -109.23,
            54.58,
            -109.24,
            54.61,
            -109.34,
            54.6,
            -109.35,
            54.74,
            -109.44,
            54.79,
            -109.47,
            55.38,
            -109.39,
            55.43,
            -109.39,
            55.57,
            -109.29,
            55.58,
            -109.44,
            58.38,
            -109.55,
            58.37,
            -109.55,
            58.51,
            -109.64,
            58.56,
            -109.67,
            59.15,
            -109.59,
            59.2,
            -109.6,
            59.34,
            -109.49,
            59.35,
            -109.64,
            62.14,
            -109.64,
            62.15,
            -109.75,
            62.15,
            -109.76,
            62.29,
            -109.85,
            62.33,
            -109.88,
            62.92,
            -109.79,
            62.98,
            -109.8,
            63.12,
            -109.7,
            63.12,
            -109.85,
            65.94,
            -109.95,
            65.93,
            -109.96,
            66.07,
            -110.05,
            66.12,
            -110.08,
            66.7,
            -110,
            66.76,
            -110.01,
            66.9,
            -109.9,
            66.91,
            -110.04,
            69.49,
            -110.13,
            69.49,
            -110.14,
            69.63,
            -110.23,
            69.68,
            -110.26,
            70.26,
            -110.18,
            70.32,
            -110.18,
            70.46,
            -110.09,
            70.46,
            -110.24,
            73.09,
            -110.32,
            73.08,
            -110.33,
            73.22,
            -110.42,
            73.27,
            -110.45,
            73.86,
            -110.36,
            73.91,
            -110.37,
            74.05,
            -110.29,
            74.06,
            -110.43,
            76.7,
            -110.51,
            76.7,
            -110.52,
            76.84,
            -110.61,
            76.89,
            -110.64,
            77.47,
            -110.55,
            77.53,
            -110.56,
            77.67,
            -110.48,
            77.67,
            -110.63,
            80.28,
            -110.72,
            80.28,
            -110.73,
            80.42,
            -110.82,
            80.47,
            -110.85,
            81.05,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.14,
            -33.56,
            85.14,
            -33.56,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -6.9,
            85.22,
            -6.9,
            85.19,
            -6.9,
            85.14,
            -6.6,
            85.16,
            -3.23,
            42.69,
            -3.53,
            42.67,
            -3.6,
            42.66,
            -3.58,
            42.4,
            -3.12,
            42.44,
            -2.11,
            28.92,
            -2.01,
            28.92,
            -1.69,
            12.32,
            -1.66,
            11.47,
            -1.71,
            10.68,
            -1.7,
            9.8,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.87,
            10.89,
            -11.73,
            10.89,
            -20.73,
            11.09,
            -45.87,
            11.06,
            -67.3,
            10.93,
            -85.69,
            10.93,
            -85.69,
            12.09,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.12,
            24.2,
            -61.12,
            29.41,
            -45.97,
            29.63,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et5_phase00": [
         [
            -108.27,
            43.79,
            -108.74,
            43.79,
            -108.74,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.56,
            54.31,
            -45.45,
            54.31,
            -45.45,
            42.87,
            -45.46,
            29.76,
            -45.98,
            29.76,
            -61.34,
            29.76,
            -61.27,
            24.48,
            -67.43,
            24.6,
            -67.43,
            29.49,
            -72.24,
            29.54,
            -72.27,
            30.25,
            -85.83,
            30.25,
            -85.83,
            12.34,
            -77.93,
            12.31,
            -75.26,
            12.18,
            -64.89,
            12.13,
            -50.92,
            12.34,
            -40.65,
            12.4,
            -8.32,
            12.03,
            -8.33,
            11.69,
            -7.87,
            11.69,
            -7.87,
            11.51,
            -1.66,
            11.4,
            -1.68,
            12.54,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -5.75,
            28.97,
            -6.19,
            41.91,
            -6.04,
            41.91,
            -6.03,
            42.43,
            -5.42,
            42.42,
            -5.42,
            42.58,
            -5.42,
            42.72,
            -14.43,
            42.85,
            -17.87,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.6,
            81.04,
            -110.6,
            80.68,
            -110.23,
            80.68,
            -108.27,
            43.79
         ]
      ],
      "rocade_fond_et5_phase01": [
         [
            -108.27,
            43.79,
            -108.74,
            43.79,
            -108.74,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.56,
            54.31,
            -45.45,
            54.31,
            -45.45,
            42.87,
            -45.46,
            29.76,
            -45.98,
            29.76,
            -61.34,
            29.76,
            -61.27,
            24.48,
            -67.43,
            24.6,
            -67.43,
            29.49,
            -72.24,
            29.54,
            -72.27,
            30.25,
            -85.83,
            30.25,
            -85.83,
            12.34,
            -77.93,
            12.31,
            -75.26,
            12.18,
            -64.89,
            12.13,
            -50.92,
            12.34,
            -40.65,
            12.4,
            -8.32,
            12.03,
            -8.33,
            11.69,
            -7.87,
            11.69,
            -7.87,
            11.51,
            -1.66,
            11.4,
            -1.68,
            12.54,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -5.75,
            28.97,
            -6.19,
            41.91,
            -6.04,
            41.91,
            -6.03,
            42.43,
            -5.42,
            42.42,
            -5.42,
            42.58,
            -5.42,
            42.72,
            -14.43,
            42.85,
            -17.87,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.6,
            81.04,
            -110.6,
            80.68,
            -110.23,
            80.68,
            -108.27,
            43.79
         ]
      ],
      "rocade_fond_et5_phase02_03": [
         [
            -108.27,
            43.79,
            -108.74,
            43.79,
            -108.74,
            43.06,
            -95.14,
            43.06,
            -59.64,
            43.07,
            -59.56,
            54.31,
            -45.45,
            54.31,
            -45.45,
            42.87,
            -45.46,
            29.76,
            -45.98,
            29.76,
            -61.34,
            29.76,
            -61.27,
            24.48,
            -67.43,
            24.6,
            -67.43,
            29.49,
            -72.24,
            29.54,
            -72.27,
            30.25,
            -85.83,
            30.25,
            -85.83,
            12.34,
            -77.93,
            12.31,
            -75.26,
            12.18,
            -64.89,
            12.13,
            -50.92,
            12.34,
            -40.65,
            12.4,
            -8.32,
            12.03,
            -8.33,
            11.69,
            -7.87,
            11.69,
            -7.87,
            11.51,
            -1.66,
            11.4,
            -1.68,
            12.54,
            -2.01,
            28.92,
            -2.11,
            28.92,
            -5.75,
            28.97,
            -6.19,
            41.91,
            -6.04,
            41.91,
            -6.03,
            42.43,
            -5.42,
            42.42,
            -5.42,
            42.58,
            -5.42,
            42.72,
            -14.43,
            42.85,
            -17.87,
            85.22,
            -18.99,
            85.22,
            -31.99,
            85.22,
            -31.99,
            85.44,
            -33.91,
            85.44,
            -33.91,
            85.22,
            -75.02,
            85.22,
            -75.03,
            81.04,
            -110.6,
            81.04,
            -110.6,
            80.68,
            -110.23,
            80.68,
            -108.27,
            43.79
         ]
      ],
      "rocade_fond_et5_phase04_05": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.23,
            -35.91,
            29.15,
            -35.91,
            25.86,
            -35.53,
            25.84,
            -35.19,
            25.67,
            -34.96,
            25.36,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -25.18,
            29.32,
            -16.47,
            29.32,
            -17.75,
            30.59,
            -17.9,
            36.56,
            -27.02,
            36.78,
            -26.96,
            40.83,
            -20.04,
            40.65,
            -20.04,
            38.26,
            -18.54,
            38.26,
            -18.54,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -108.74,
            43.06,
            -108.74,
            43.79,
            -108.27,
            43.79,
            -110.23,
            80.68,
            -110.6,
            80.68,
            -110.6,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -12.46,
            85.22,
            -12.46,
            85.17,
            -8.99,
            42.76,
            -5.41,
            42.73,
            -5.42,
            42.42,
            -5.47,
            42.42,
            -5.41,
            42.31,
            -2.53,
            42.31,
            -1.56,
            28.92,
            -2.25,
            29,
            -2.24,
            28.65,
            -2.01,
            28.65,
            -1.69,
            12.32,
            -1.66,
            11.47,
            -1.71,
            10.68,
            -1.7,
            9.8,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.86,
            11.89,
            -8.32,
            11.89,
            -8.32,
            12.03,
            -21.22,
            12.15,
            -40.65,
            12.4,
            -47.47,
            12.36,
            -64.89,
            12.13,
            -75.26,
            12.18,
            -77.78,
            12.31,
            -85.68,
            12.34,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.12,
            24.2,
            -61.12,
            29.41,
            -45.97,
            29.63,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et5_phase06": [
         [
            -41.75,
            24.02,
            -41.77,
            24.43,
            -41.77,
            24.84,
            -41.6,
            25.22,
            -41.3,
            25.5,
            -40.91,
            25.64,
            -40.5,
            25.62,
            -40.5,
            29.23,
            -35.91,
            29.15,
            -35.91,
            25.86,
            -35.53,
            25.84,
            -35.19,
            25.67,
            -34.96,
            25.36,
            -34.89,
            24.99,
            -34.83,
            24.34,
            -25.01,
            24.35,
            -25.18,
            29.32,
            -16.47,
            29.32,
            -17.75,
            30.59,
            -17.9,
            36.56,
            -27.02,
            36.78,
            -26.96,
            40.83,
            -20.04,
            40.65,
            -20.04,
            38.26,
            -18.54,
            38.26,
            -18.54,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.96,
            42.02,
            -74.96,
            42.52,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -108.74,
            43.06,
            -108.74,
            43.79,
            -108.27,
            43.79,
            -110.23,
            80.68,
            -110.6,
            80.68,
            -110.6,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -33.91,
            85.22,
            -33.91,
            85.44,
            -31.99,
            85.44,
            -31.99,
            85.22,
            -18.07,
            85.22,
            -12.46,
            85.22,
            -12.46,
            85.17,
            -8.99,
            42.76,
            -5.41,
            42.73,
            -5.42,
            42.42,
            -5.47,
            42.42,
            -5.41,
            42.31,
            -2.53,
            42.31,
            -1.56,
            28.92,
            -2.25,
            29,
            -2.24,
            28.65,
            -2.01,
            28.65,
            -1.69,
            12.32,
            -1.66,
            11.47,
            -1.71,
            10.68,
            -1.7,
            9.8,
            -1.68,
            7.38,
            -2.08,
            3.16,
            -8.34,
            3.6,
            -8.04,
            7.8,
            -7.99,
            8.42,
            -8.01,
            8.42,
            -8,
            8.67,
            -7.95,
            8.67,
            -7.92,
            9.87,
            -7.86,
            11.89,
            -8.32,
            11.89,
            -8.32,
            12.03,
            -21.22,
            12.15,
            -40.65,
            12.4,
            -47.47,
            12.36,
            -64.89,
            12.13,
            -75.26,
            12.18,
            -77.78,
            12.31,
            -85.68,
            12.34,
            -85.66,
            13.12,
            -85.39,
            29.82,
            -83.74,
            29.84,
            -79.89,
            29.83,
            -67.67,
            29.56,
            -67.67,
            24.2,
            -61.12,
            24.2,
            -61.12,
            29.41,
            -45.97,
            29.63,
            -45.97,
            24.05,
            -41.75,
            24.02
         ]
      ],
      "rocade_fond_et6_phase00": [
         [
            -75.03,
            81.04,
            -107.71,
            81.04,
            -105.69,
            43.06,
            -59.64,
            43.07,
            -59.6,
            57.77,
            -45.45,
            57.77,
            -45.45,
            42.87,
            -20.21,
            42.58,
            -19.89,
            42.58,
            -19.92,
            42.95,
            -23.33,
            85.25,
            -23.55,
            85.23,
            -23.59,
            85.22,
            -75.03,
            85.22,
            -75.03,
            81.04
         ]
      ],
      "rocade_fond_et6_phase01": [
         [
            -75.03,
            81.04,
            -107.71,
            81.04,
            -105.69,
            43.06,
            -59.64,
            43.07,
            -59.6,
            57.77,
            -45.45,
            57.77,
            -45.45,
            42.87,
            -20.21,
            42.58,
            -19.89,
            42.58,
            -19.92,
            42.95,
            -23.33,
            85.25,
            -23.55,
            85.23,
            -23.59,
            85.22,
            -75.03,
            85.22,
            -75.03,
            81.04
         ]
      ],
      "rocade_fond_et6_phase02_03": [
         [
            -75.03,
            81.04,
            -107.71,
            81.04,
            -105.69,
            43.06,
            -59.64,
            43.07,
            -59.6,
            57.77,
            -45.45,
            57.77,
            -45.45,
            42.87,
            -20.21,
            42.58,
            -19.89,
            42.58,
            -19.92,
            42.95,
            -23.33,
            85.25,
            -23.55,
            85.23,
            -23.59,
            85.22,
            -75.03,
            85.22,
            -75.03,
            81.04
         ]
      ],
      "rocade_fond_et6_phase04_05": [
         [
            -107.69,
            80.74,
            -108.37,
            80.74,
            -108.37,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -23.59,
            85.22,
            -17.62,
            85.22,
            -17.62,
            85.17,
            -14.09,
            42.12,
            -19.69,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.78,
            42.02,
            -74.78,
            42.52,
            -83.17,
            42.57,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -92.17,
            43.06,
            -106.28,
            43.06,
            -106.28,
            43.41,
            -105.71,
            43.41,
            -107.69,
            80.74
         ]
      ],
      "rocade_fond_et6_phase06": [
         [
            -107.69,
            80.74,
            -108.37,
            80.74,
            -108.37,
            81.04,
            -75.03,
            81.04,
            -75.03,
            85.22,
            -23.59,
            85.22,
            -17.62,
            85.22,
            -17.62,
            85.17,
            -14.09,
            42.12,
            -19.69,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.02,
            -74.78,
            42.02,
            -74.78,
            42.52,
            -83.17,
            42.57,
            -88.28,
            42.61,
            -88.28,
            43.06,
            -92.17,
            43.06,
            -106.28,
            43.06,
            -106.28,
            43.41,
            -105.71,
            43.41,
            -107.69,
            80.74
         ]
      ],
      "rocade_fond_et7_phase00": [
         [
            -90.43,
            43.06,
            -59.64,
            43.07,
            -59.59,
            57.72,
            -45.45,
            57.72,
            -45.45,
            42.87,
            -22.01,
            42.61,
            -26.08,
            85.22,
            -75.03,
            85.19,
            -75.03,
            80.79,
            -82.39,
            80.79,
            -82.31,
            73.6,
            -82.31,
            73.4,
            -74.91,
            73.4,
            -74.91,
            66.6,
            -82.35,
            66.6,
            -82.41,
            50,
            -85.06,
            50,
            -85.06,
            48.11,
            -85.06,
            48.09,
            -85.14,
            48.09,
            -85.14,
            47.92,
            -86.14,
            47.92,
            -86.14,
            45.84,
            -90.43,
            45.84,
            -90.43,
            43.06
         ]
      ],
      "rocade_fond_et7_phase01": [
         [
            -90.43,
            43.06,
            -59.64,
            43.07,
            -59.59,
            57.72,
            -45.45,
            57.72,
            -45.45,
            42.87,
            -22.01,
            42.61,
            -26.08,
            85.22,
            -75.03,
            85.19,
            -75.03,
            80.79,
            -82.39,
            80.79,
            -82.31,
            73.6,
            -82.31,
            73.4,
            -74.91,
            73.4,
            -74.91,
            66.6,
            -82.35,
            66.6,
            -82.41,
            50,
            -85.06,
            50,
            -85.06,
            48.11,
            -85.06,
            48.09,
            -85.14,
            48.09,
            -85.14,
            47.92,
            -86.14,
            47.92,
            -86.14,
            45.84,
            -90.43,
            45.84,
            -90.43,
            43.06
         ]
      ],
      "rocade_fond_et7_phase02_03": [
         [
            -90.43,
            43.06,
            -59.64,
            43.07,
            -59.59,
            57.72,
            -45.45,
            57.72,
            -45.45,
            42.87,
            -22.01,
            42.61,
            -26.08,
            85.22,
            -75.03,
            85.19,
            -75.03,
            80.79,
            -82.39,
            80.79,
            -82.31,
            73.6,
            -82.31,
            73.4,
            -74.91,
            73.4,
            -74.91,
            66.6,
            -82.35,
            66.6,
            -82.41,
            50,
            -85.06,
            50,
            -85.06,
            48.11,
            -85.06,
            48.09,
            -85.14,
            48.09,
            -85.14,
            47.92,
            -86.14,
            47.92,
            -86.14,
            45.84,
            -90.43,
            45.84,
            -90.43,
            43.06
         ]
      ],
      "rocade_fond_et7_phase04_05": [
         [
            -95.19,
            76.94,
            -95.19,
            80.8,
            -82.35,
            80.66,
            -82.34,
            80.79,
            -75.03,
            80.79,
            -75.03,
            85.19,
            -74.9,
            85.19,
            -50.52,
            85.22,
            -50.52,
            84.65,
            -42.4,
            84.65,
            -42.4,
            84.09,
            -37.99,
            84.09,
            -29.81,
            84.09,
            -29.81,
            84.96,
            -26.65,
            84.96,
            -26.65,
            85.22,
            -23.59,
            85.22,
            -23.59,
            84.94,
            -23.47,
            85.15,
            -23.17,
            85.18,
            -19.69,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.52,
            -88.28,
            42.61,
            -88.27,
            43.19,
            -90.41,
            43.2,
            -90.4,
            43.4,
            -94.99,
            43.43,
            -94.99,
            43.17,
            -95.32,
            43.17,
            -98.95,
            43.17,
            -98.95,
            76.94,
            -95.32,
            76.94,
            -95.19,
            76.94
         ]
      ],
      "rocade_fond_et7_phase06": [
         [
            -95.19,
            76.94,
            -95.19,
            80.8,
            -82.35,
            80.66,
            -82.34,
            80.79,
            -75.03,
            80.79,
            -75.03,
            85.19,
            -74.9,
            85.19,
            -50.52,
            85.22,
            -50.52,
            84.65,
            -42.4,
            84.65,
            -42.4,
            84.09,
            -37.99,
            84.09,
            -29.81,
            84.09,
            -29.81,
            84.96,
            -26.65,
            84.96,
            -26.65,
            85.22,
            -23.59,
            85.22,
            -23.59,
            84.94,
            -23.47,
            85.15,
            -23.17,
            85.18,
            -19.69,
            42.17,
            -46.12,
            42.34,
            -46.11,
            44.19,
            -53.9,
            44.24,
            -53.92,
            41.49,
            -74.94,
            41.62,
            -74.94,
            42.52,
            -88.28,
            42.61,
            -88.27,
            43.19,
            -90.41,
            43.2,
            -90.4,
            43.4,
            -94.99,
            43.43,
            -94.99,
            43.17,
            -95.32,
            43.17,
            -98.95,
            43.17,
            -98.95,
            76.94,
            -95.32,
            76.94,
            -95.19,
            76.94
         ]
      ],
      "rocade_fond_rdc_phase00": [
         [
            -55.38,
            85.33,
            -55.38,
            85.22,
            -75.05,
            85.22,
            -75.05,
            80.85,
            -110.83,
            80.85,
            -110.84,
            81.01,
            -111.06,
            80.99,
            -111.05,
            80.84,
            -111.14,
            80.84,
            -110.62,
            71.09,
            -110.15,
            71.12,
            -110.15,
            71.03,
            -110,
            71.04,
            -110,
            70.99,
            -109.79,
            67.01,
            -110.53,
            66.98,
            -110.43,
            65.35,
            -108.84,
            65.44,
            -108.53,
            59.95,
            -110.14,
            59.86,
            -110.07,
            58.54,
            -109.95,
            58.55,
            -109.35,
            47.17,
            -108.87,
            47.2,
            -108.7,
            44.03,
            -108.99,
            44.02,
            -108.97,
            43.78,
            -108.86,
            43.78,
            -108.86,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -74.43,
            43.01,
            -74.43,
            31.26,
            -74.08,
            31.26,
            -74.08,
            29.03,
            -76.55,
            29.12,
            -76.55,
            29.28,
            -77.59,
            29.28,
            -77.59,
            29.13,
            -80.45,
            29.13,
            -80.45,
            29.52,
            -85.4,
            29.52,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.74,
            11.27,
            -84.74,
            11.41,
            -81.08,
            11.45,
            -81.08,
            11.34,
            -80.53,
            11.34,
            -80.53,
            11.45,
            -76.74,
            11.47,
            -76.74,
            11.35,
            -76.19,
            11.36,
            -76.19,
            11.48,
            -72.43,
            11.5,
            -72.43,
            11.38,
            -71.88,
            11.38,
            -71.88,
            11.5,
            -68.13,
            11.51,
            -68.13,
            11.39,
            -67.58,
            11.39,
            -67.58,
            11.51,
            -67.33,
            11.51,
            -67.33,
            12.3,
            -64.05,
            12.31,
            -64.04,
            11.52,
            -63.79,
            11.52,
            -63.79,
            11.4,
            -63.24,
            11.41,
            -63.24,
            11.53,
            -59.52,
            11.55,
            -59.52,
            11.43,
            -58.97,
            11.43,
            -58.97,
            11.55,
            -55.69,
            11.55,
            -55.69,
            11.43,
            -54.14,
            11.43,
            -54.14,
            11.55,
            -51.44,
            11.53,
            -51.44,
            11.4,
            -49.87,
            11.4,
            -49.87,
            11.73,
            -49.31,
            11.73,
            -47.13,
            11.73,
            -47.13,
            11.4,
            -45.56,
            11.4,
            -45.55,
            11.73,
            -44.97,
            11.73,
            -42.81,
            11.72,
            -42.82,
            11.39,
            -41.25,
            11.39,
            -41.25,
            11.51,
            -38.05,
            11.5,
            -38.05,
            11.38,
            -36.83,
            11.38,
            -36.83,
            11.75,
            -34.03,
            11.74,
            -34.03,
            11.37,
            -32.6,
            11.36,
            -32.6,
            11.49,
            -29.82,
            11.47,
            -29.82,
            11.34,
            -28.29,
            11.33,
            -28.29,
            11.46,
            -25.52,
            11.44,
            -25.52,
            11.31,
            -24,
            11.3,
            -24,
            11.43,
            -21.16,
            11.41,
            -21.16,
            11.28,
            -19.7,
            11.27,
            -19.7,
            11.4,
            -16.93,
            11.38,
            -16.93,
            11.26,
            -16.46,
            11.26,
            -16.46,
            11.41,
            -15.86,
            11.41,
            -15.86,
            11.56,
            -15.61,
            11.55,
            -15.61,
            11.66,
            -15.46,
            11.66,
            -15.46,
            11.72,
            -12.56,
            11.72,
            -12.56,
            11.65,
            -12.41,
            11.66,
            -12.41,
            11.56,
            -12.16,
            11.56,
            -12.16,
            11.41,
            -11.55,
            11.41,
            -11.55,
            11.55,
            -11.3,
            11.56,
            -11.29,
            11.66,
            -11.15,
            11.66,
            -11.15,
            11.72,
            -8.25,
            11.72,
            -8.25,
            11.66,
            -8.1,
            11.66,
            -8.1,
            11.56,
            -7.85,
            11.56,
            -7.85,
            11.41,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.3,
            4.17,
            -8.63,
            4.19,
            -8.69,
            3.3,
            -7.8,
            3.23,
            -7.77,
            3.56,
            -2.66,
            3.2,
            -2.67,
            2.9,
            -1.77,
            2.83,
            -1.71,
            3.73,
            -2.03,
            3.75,
            -1.68,
            7.38,
            -1.74,
            10.69,
            -1.71,
            10.72,
            -1.7,
            11.39,
            -2.04,
            28.82,
            -2.07,
            28.82,
            -2.31,
            31.98,
            -2.5,
            34.58,
            -2.73,
            34.57,
            -2.88,
            36.56,
            -2.65,
            36.58,
            -2.89,
            39.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.55,
            44.28,
            -6.52,
            44.28,
            -6.52,
            43.86,
            -6.79,
            43.86,
            -6.79,
            49.84,
            -6.47,
            49.84,
            -6.47,
            50.12,
            -7.41,
            50.12,
            -7.41,
            52.3,
            -7.35,
            52.3,
            -7.34,
            52.93,
            -7.76,
            52.92,
            -7.76,
            53.94,
            -7.88,
            53.94,
            -7.88,
            54.98,
            -8,
            54.98,
            -8,
            59.09,
            -7.88,
            59.09,
            -7.88,
            60.14,
            -7.32,
            60.14,
            -7.32,
            60.46,
            -7.39,
            60.46,
            -7.39,
            65.74,
            -7.32,
            65.74,
            -7.32,
            66.06,
            -8.23,
            66.06,
            -8.23,
            67.08,
            -8.47,
            67.08,
            -8.47,
            72.95,
            -8.23,
            72.95,
            -8.23,
            74.13,
            -7.93,
            74.13,
            -7.93,
            74.38,
            -6.05,
            74.37,
            -6.05,
            74.13,
            -5.65,
            74.13,
            -6.16,
            80.48,
            -8.15,
            80.48,
            -8.15,
            80.61,
            -8.15,
            84.79,
            -6.07,
            84.79,
            -6.07,
            85.22,
            -51.84,
            85.22,
            -51.84,
            85.33,
            -51.96,
            85.33,
            -51.96,
            85.22,
            -55.26,
            85.22,
            -55.26,
            85.33,
            -55.38,
            85.33
         ]
      ],
      "rocade_fond_rdc_phase01": [
         [
            -55.38,
            85.33,
            -55.38,
            85.22,
            -75.05,
            85.22,
            -75.05,
            80.85,
            -110.83,
            80.85,
            -110.84,
            81.01,
            -111.06,
            80.99,
            -111.05,
            80.84,
            -111.14,
            80.84,
            -110.62,
            71.09,
            -110.15,
            71.12,
            -110.15,
            71.03,
            -110,
            71.04,
            -110,
            70.99,
            -109.79,
            67.01,
            -110.53,
            66.98,
            -110.43,
            65.35,
            -108.84,
            65.44,
            -108.53,
            59.95,
            -110.14,
            59.86,
            -110.07,
            58.54,
            -109.95,
            58.55,
            -109.35,
            47.17,
            -108.87,
            47.2,
            -108.7,
            44.03,
            -108.99,
            44.02,
            -108.97,
            43.78,
            -108.86,
            43.78,
            -108.86,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -74.43,
            43.01,
            -74.43,
            31.26,
            -74.08,
            31.26,
            -74.08,
            29.03,
            -76.55,
            29.12,
            -76.55,
            29.28,
            -77.59,
            29.28,
            -77.59,
            29.13,
            -80.45,
            29.13,
            -80.45,
            29.52,
            -85.4,
            29.52,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.74,
            11.27,
            -84.74,
            11.41,
            -81.08,
            11.45,
            -81.08,
            11.34,
            -80.53,
            11.34,
            -80.53,
            11.45,
            -76.74,
            11.47,
            -76.74,
            11.35,
            -76.19,
            11.36,
            -76.19,
            11.48,
            -72.43,
            11.5,
            -72.43,
            11.38,
            -71.88,
            11.38,
            -71.88,
            11.5,
            -68.13,
            11.51,
            -68.13,
            11.39,
            -67.58,
            11.39,
            -67.58,
            11.51,
            -67.33,
            11.51,
            -67.33,
            12.3,
            -64.05,
            12.31,
            -64.04,
            11.52,
            -63.79,
            11.52,
            -63.79,
            11.4,
            -63.24,
            11.41,
            -63.24,
            11.53,
            -59.52,
            11.55,
            -59.52,
            11.43,
            -58.97,
            11.43,
            -58.97,
            11.55,
            -55.69,
            11.55,
            -55.69,
            11.43,
            -54.14,
            11.43,
            -54.14,
            11.55,
            -51.44,
            11.53,
            -51.44,
            11.4,
            -49.87,
            11.4,
            -49.87,
            11.73,
            -49.31,
            11.73,
            -47.13,
            11.73,
            -47.13,
            11.4,
            -45.56,
            11.4,
            -45.55,
            11.73,
            -44.97,
            11.73,
            -42.81,
            11.72,
            -42.82,
            11.39,
            -41.25,
            11.39,
            -41.25,
            11.51,
            -38.05,
            11.5,
            -38.05,
            11.38,
            -36.83,
            11.38,
            -36.83,
            11.75,
            -34.03,
            11.74,
            -34.03,
            11.37,
            -32.6,
            11.36,
            -32.6,
            11.49,
            -29.82,
            11.47,
            -29.82,
            11.34,
            -28.29,
            11.33,
            -28.29,
            11.46,
            -25.52,
            11.44,
            -25.52,
            11.31,
            -24,
            11.3,
            -24,
            11.43,
            -21.16,
            11.41,
            -21.16,
            11.28,
            -19.7,
            11.27,
            -19.7,
            11.4,
            -16.93,
            11.38,
            -16.93,
            11.26,
            -16.46,
            11.26,
            -16.46,
            11.41,
            -15.86,
            11.41,
            -15.86,
            11.56,
            -15.61,
            11.55,
            -15.61,
            11.66,
            -15.46,
            11.66,
            -15.46,
            11.72,
            -12.56,
            11.72,
            -12.56,
            11.65,
            -12.41,
            11.66,
            -12.41,
            11.56,
            -12.16,
            11.56,
            -12.16,
            11.41,
            -11.55,
            11.41,
            -11.55,
            11.55,
            -11.3,
            11.56,
            -11.29,
            11.66,
            -11.15,
            11.66,
            -11.15,
            11.72,
            -8.25,
            11.72,
            -8.25,
            11.66,
            -8.1,
            11.66,
            -8.1,
            11.56,
            -7.85,
            11.56,
            -7.85,
            11.41,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.3,
            4.17,
            -8.63,
            4.19,
            -8.69,
            3.3,
            -7.8,
            3.23,
            -7.77,
            3.56,
            -2.66,
            3.2,
            -2.67,
            2.9,
            -1.77,
            2.83,
            -1.71,
            3.73,
            -2.03,
            3.75,
            -1.68,
            7.38,
            -1.74,
            10.69,
            -1.71,
            10.72,
            -1.7,
            11.39,
            -2.04,
            28.82,
            -2.07,
            28.82,
            -2.31,
            31.98,
            -2.5,
            34.58,
            -2.73,
            34.57,
            -2.88,
            36.56,
            -2.65,
            36.58,
            -2.89,
            39.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.55,
            44.28,
            -6.52,
            44.28,
            -6.52,
            43.86,
            -6.79,
            43.86,
            -6.79,
            49.84,
            -6.47,
            49.84,
            -6.47,
            50.12,
            -7.41,
            50.12,
            -7.41,
            52.3,
            -7.35,
            52.3,
            -7.34,
            52.93,
            -7.76,
            52.92,
            -7.76,
            53.94,
            -7.88,
            53.94,
            -7.88,
            54.98,
            -8,
            54.98,
            -8,
            59.09,
            -7.88,
            59.09,
            -7.88,
            60.14,
            -7.32,
            60.14,
            -7.32,
            60.46,
            -7.39,
            60.46,
            -7.39,
            65.74,
            -7.32,
            65.74,
            -7.32,
            66.06,
            -8.23,
            66.06,
            -8.23,
            67.08,
            -8.47,
            67.08,
            -8.47,
            72.95,
            -8.23,
            72.95,
            -8.23,
            74.13,
            -7.93,
            74.13,
            -7.93,
            74.38,
            -6.05,
            74.37,
            -6.05,
            74.13,
            -5.65,
            74.13,
            -6.16,
            80.48,
            -8.15,
            80.48,
            -8.15,
            80.61,
            -8.15,
            84.79,
            -6.07,
            84.79,
            -6.07,
            85.22,
            -51.84,
            85.22,
            -51.84,
            85.33,
            -51.96,
            85.33,
            -51.96,
            85.22,
            -55.26,
            85.22,
            -55.26,
            85.33,
            -55.38,
            85.33
         ]
      ],
      "rocade_fond_rdc_phase02_03": [
         [
            -55.38,
            85.33,
            -55.38,
            85.22,
            -75.05,
            85.22,
            -75.05,
            80.85,
            -110.83,
            80.85,
            -110.84,
            81.01,
            -111.06,
            80.99,
            -111.05,
            80.84,
            -111.14,
            80.84,
            -110.62,
            71.09,
            -110.15,
            71.12,
            -110.15,
            71.03,
            -110,
            71.04,
            -110,
            70.99,
            -109.79,
            67.01,
            -110.53,
            66.98,
            -110.43,
            65.35,
            -108.84,
            65.44,
            -108.53,
            59.95,
            -110.14,
            59.86,
            -110.07,
            58.54,
            -109.95,
            58.55,
            -109.35,
            47.17,
            -108.87,
            47.2,
            -108.7,
            44.03,
            -108.99,
            44.02,
            -108.97,
            43.78,
            -108.86,
            43.78,
            -108.86,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -74.43,
            43.01,
            -74.43,
            31.26,
            -74.08,
            31.26,
            -74.08,
            29.03,
            -76.55,
            29.12,
            -76.55,
            29.28,
            -77.59,
            29.28,
            -77.59,
            29.13,
            -80.45,
            29.13,
            -80.45,
            29.52,
            -85.4,
            29.52,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.84,
            10.72,
            -84.84,
            11.27,
            -84.74,
            11.27,
            -84.74,
            11.41,
            -81.08,
            11.45,
            -81.08,
            11.34,
            -80.53,
            11.34,
            -80.53,
            11.45,
            -76.74,
            11.47,
            -76.74,
            11.35,
            -76.19,
            11.36,
            -76.19,
            11.48,
            -72.43,
            11.5,
            -72.43,
            11.38,
            -71.88,
            11.38,
            -71.88,
            11.5,
            -68.13,
            11.51,
            -68.13,
            11.39,
            -67.58,
            11.39,
            -67.58,
            11.51,
            -67.33,
            11.51,
            -67.33,
            12.3,
            -64.05,
            12.31,
            -64.04,
            11.52,
            -63.79,
            11.52,
            -63.79,
            11.4,
            -63.24,
            11.41,
            -63.24,
            11.53,
            -59.52,
            11.55,
            -59.52,
            11.43,
            -58.97,
            11.43,
            -58.97,
            11.55,
            -55.69,
            11.55,
            -55.69,
            11.43,
            -54.14,
            11.43,
            -54.14,
            11.55,
            -51.44,
            11.53,
            -51.44,
            11.4,
            -49.87,
            11.4,
            -49.87,
            11.73,
            -49.31,
            11.73,
            -47.13,
            11.73,
            -47.13,
            11.4,
            -45.56,
            11.4,
            -45.55,
            11.73,
            -44.97,
            11.73,
            -42.81,
            11.72,
            -42.82,
            11.39,
            -41.25,
            11.39,
            -41.25,
            11.51,
            -38.05,
            11.5,
            -38.05,
            11.38,
            -36.83,
            11.38,
            -36.83,
            11.75,
            -34.03,
            11.74,
            -34.03,
            11.37,
            -32.6,
            11.36,
            -32.6,
            11.49,
            -29.82,
            11.47,
            -29.82,
            11.34,
            -28.29,
            11.33,
            -28.29,
            11.46,
            -25.52,
            11.44,
            -25.52,
            11.31,
            -24,
            11.3,
            -24,
            11.43,
            -21.16,
            11.41,
            -21.16,
            11.28,
            -19.7,
            11.27,
            -19.7,
            11.4,
            -16.93,
            11.38,
            -16.93,
            11.26,
            -16.46,
            11.26,
            -16.46,
            11.41,
            -15.86,
            11.41,
            -15.86,
            11.56,
            -15.61,
            11.55,
            -15.61,
            11.66,
            -15.46,
            11.66,
            -15.46,
            11.72,
            -12.56,
            11.72,
            -12.56,
            11.65,
            -12.41,
            11.66,
            -12.41,
            11.56,
            -12.16,
            11.56,
            -12.16,
            11.41,
            -11.55,
            11.41,
            -11.55,
            11.55,
            -11.3,
            11.56,
            -11.29,
            11.66,
            -11.15,
            11.66,
            -11.15,
            11.72,
            -8.25,
            11.72,
            -8.25,
            11.66,
            -8.1,
            11.66,
            -8.1,
            11.56,
            -7.85,
            11.56,
            -7.85,
            11.41,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.3,
            4.17,
            -8.63,
            4.19,
            -8.69,
            3.3,
            -7.8,
            3.23,
            -7.77,
            3.56,
            -2.66,
            3.2,
            -2.67,
            2.9,
            -1.77,
            2.83,
            -1.71,
            3.73,
            -2.03,
            3.75,
            -1.68,
            7.38,
            -1.74,
            10.69,
            -1.71,
            10.72,
            -1.7,
            11.39,
            -2.04,
            28.82,
            -2.07,
            28.82,
            -2.31,
            31.98,
            -2.5,
            34.58,
            -2.73,
            34.57,
            -2.88,
            36.56,
            -2.65,
            36.58,
            -2.89,
            39.78,
            -3.09,
            42.44,
            -3.41,
            42.42,
            -3.55,
            44.28,
            -6.52,
            44.28,
            -6.52,
            43.86,
            -6.79,
            43.86,
            -6.79,
            49.84,
            -6.47,
            49.84,
            -6.47,
            50.12,
            -7.41,
            50.12,
            -7.41,
            52.3,
            -7.35,
            52.3,
            -7.34,
            52.93,
            -7.76,
            52.92,
            -7.76,
            53.94,
            -7.88,
            53.94,
            -7.88,
            54.98,
            -8,
            54.98,
            -8,
            59.09,
            -7.88,
            59.09,
            -7.88,
            60.14,
            -7.32,
            60.14,
            -7.32,
            60.46,
            -7.39,
            60.46,
            -7.39,
            65.74,
            -7.32,
            65.74,
            -7.32,
            66.06,
            -8.23,
            66.06,
            -8.23,
            67.08,
            -8.47,
            67.08,
            -8.47,
            72.95,
            -8.23,
            72.95,
            -8.23,
            74.13,
            -7.93,
            74.13,
            -7.93,
            74.38,
            -6.05,
            74.37,
            -6.05,
            74.13,
            -5.65,
            74.13,
            -6.16,
            80.48,
            -8.15,
            80.48,
            -8.15,
            80.61,
            -8.15,
            84.79,
            -6.07,
            84.79,
            -6.07,
            85.22,
            -51.84,
            85.22,
            -51.84,
            85.33,
            -51.96,
            85.33,
            -51.96,
            85.22,
            -55.26,
            85.22,
            -55.26,
            85.33,
            -55.38,
            85.33
         ]
      ],
      "rocade_fond_rdc_phase04_05": [
         [
            -75.05,
            85.22,
            -75.05,
            80.85,
            -108,
            80.85,
            -108,
            80.35,
            -109.01,
            80.31,
            -109.02,
            80.47,
            -110.26,
            80.41,
            -110.26,
            80.51,
            -110.87,
            80.48,
            -110.87,
            80.45,
            -110.87,
            80.4,
            -110.91,
            80.4,
            -110.91,
            80.36,
            -111.1,
            80.35,
            -111.1,
            80.34,
            -111.22,
            80.33,
            -110.86,
            73.71,
            -110.47,
            66.48,
            -110.43,
            65.86,
            -110.12,
            65.87,
            -110.11,
            65.81,
            -109.61,
            65.84,
            -109.6,
            65.7,
            -109.58,
            65.7,
            -109.58,
            65.68,
            -109.7,
            65.67,
            -109.34,
            59.2,
            -109.23,
            59.2,
            -109.23,
            59.18,
            -109.25,
            59.18,
            -109.24,
            59.04,
            -109.74,
            59.01,
            -109.74,
            58.95,
            -110.05,
            58.93,
            -110.01,
            58.31,
            -109.64,
            51.38,
            -109.41,
            47.29,
            -109.35,
            47.29,
            -109.35,
            47.28,
            -109.16,
            47.29,
            -109.16,
            47.23,
            -108.66,
            47.26,
            -108.65,
            47.12,
            -108.63,
            47.12,
            -108.63,
            47.1,
            -107.34,
            47.17,
            -107.17,
            44.13,
            -108.46,
            44.06,
            -108.46,
            44.04,
            -108.48,
            44.04,
            -108.47,
            43.9,
            -108.98,
            43.87,
            -108.97,
            43.81,
            -109.22,
            43.79,
            -109.19,
            43.23,
            -109.11,
            43.23,
            -109.14,
            43.7,
            -108.97,
            43.71,
            -108.94,
            43.24,
            -108.86,
            43.25,
            -108.86,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -74.43,
            43.01,
            -74.43,
            37.13,
            -74.43,
            31.26,
            -74.08,
            31.26,
            -74.08,
            29.03,
            -76.55,
            29.12,
            -76.55,
            29.21,
            -76.65,
            29.21,
            -76.65,
            29.28,
            -77.59,
            29.28,
            -77.59,
            29.13,
            -80.45,
            29.13,
            -80.45,
            29.52,
            -84.72,
            29.52,
            -85.4,
            29.52,
            -85.62,
            15.81,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.85,
            10.72,
            -84.84,
            11.27,
            -84.74,
            11.27,
            -84.74,
            11.41,
            -81.08,
            11.45,
            -81.08,
            11.34,
            -80.53,
            11.34,
            -80.53,
            11.45,
            -76.74,
            11.47,
            -76.74,
            11.35,
            -76.19,
            11.36,
            -76.19,
            11.48,
            -72.43,
            11.5,
            -72.43,
            11.38,
            -71.88,
            11.38,
            -71.88,
            11.5,
            -68.37,
            11.51,
            -68.13,
            11.51,
            -68.13,
            11.39,
            -67.58,
            11.39,
            -67.58,
            11.51,
            -67.33,
            11.51,
            -67.33,
            12.27,
            -64.04,
            12.28,
            -64.04,
            11.52,
            -63.79,
            11.52,
            -63.79,
            11.4,
            -63.24,
            11.41,
            -63.24,
            11.53,
            -59.52,
            11.55,
            -59.52,
            11.43,
            -58.97,
            11.43,
            -58.97,
            11.55,
            -55.69,
            11.55,
            -55.69,
            11.43,
            -55.14,
            11.43,
            -54.14,
            11.43,
            -54.14,
            11.55,
            -51.44,
            11.53,
            -51.44,
            11.4,
            -49.87,
            11.4,
            -49.87,
            11.73,
            -47.13,
            11.73,
            -47.13,
            11.4,
            -45.56,
            11.4,
            -45.55,
            11.73,
            -42.81,
            11.72,
            -42.82,
            11.39,
            -41.25,
            11.39,
            -41.25,
            11.51,
            -38.05,
            11.5,
            -38.05,
            11.38,
            -36.83,
            11.38,
            -32.6,
            11.36,
            -32.6,
            11.49,
            -29.82,
            11.47,
            -29.82,
            11.34,
            -28.29,
            11.33,
            -28.29,
            11.46,
            -25.52,
            11.44,
            -25.52,
            11.31,
            -24,
            11.3,
            -24,
            11.43,
            -21.16,
            11.41,
            -21.16,
            11.28,
            -19.7,
            11.27,
            -19.7,
            11.4,
            -16.93,
            11.38,
            -16.93,
            11.26,
            -16.46,
            11.26,
            -16.46,
            11.41,
            -15.86,
            11.41,
            -15.86,
            11.56,
            -12.16,
            11.56,
            -12.16,
            11.41,
            -11.55,
            11.41,
            -11.54,
            11.56,
            -11.15,
            11.56,
            -7.84,
            11.56,
            -7.95,
            8.69,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.02,
            7.99,
            -8.31,
            4.16,
            -8.63,
            4.19,
            -8.69,
            3.3,
            -7.8,
            3.23,
            -7.77,
            3.56,
            -6.42,
            3.47,
            -2.66,
            3.2,
            -2.66,
            3,
            -2.67,
            2.9,
            -1.77,
            2.83,
            -1.71,
            3.73,
            -2.03,
            3.75,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.7,
            11.41,
            -2.04,
            28.82,
            -2.07,
            28.82,
            -2.5,
            34.58,
            -2.73,
            34.57,
            -2.88,
            36.56,
            -2.65,
            36.58,
            -3.09,
            42.44,
            -3.48,
            42.46,
            -3.51,
            42.86,
            -3.61,
            43.23,
            -3.84,
            43.55,
            -4.17,
            43.78,
            -4.53,
            43.86,
            -6.79,
            43.86,
            -6.79,
            49.78,
            -5.06,
            49.78,
            -4.61,
            49.91,
            -4.2,
            50.2,
            -3.92,
            50.61,
            -3.8,
            51.07,
            -4.42,
            58.85,
            -4.58,
            59.29,
            -4.89,
            59.68,
            -5.32,
            59.94,
            -5.78,
            60.04,
            -8.1,
            60.04,
            -8.1,
            65.84,
            -6.23,
            65.84,
            -5.8,
            65.95,
            -5.4,
            66.23,
            -5.15,
            66.64,
            -5.07,
            67.09,
            -6.06,
            79.53,
            -6.2,
            79.97,
            -6.49,
            80.36,
            -6.91,
            80.62,
            -7.36,
            80.72,
            -8.02,
            80.72,
            -8.02,
            84.79,
            -6.07,
            84.79,
            -6.07,
            85.22,
            -33.56,
            85.23,
            -51.84,
            85.22,
            -51.84,
            85.33,
            -55.38,
            85.33,
            -55.38,
            85.22,
            -71.65,
            85.22,
            -75.05,
            85.22
         ]
      ],
      "rocade_fond_rdc_phase06": [
         [
            -75.05,
            85.22,
            -75.05,
            80.85,
            -108,
            80.85,
            -108,
            80.35,
            -109.01,
            80.31,
            -109.02,
            80.47,
            -110.26,
            80.41,
            -110.26,
            80.51,
            -110.87,
            80.48,
            -110.87,
            80.45,
            -110.87,
            80.4,
            -110.91,
            80.4,
            -110.91,
            80.36,
            -111.1,
            80.35,
            -111.1,
            80.34,
            -111.22,
            80.33,
            -110.86,
            73.71,
            -110.47,
            66.48,
            -110.43,
            65.86,
            -110.12,
            65.87,
            -110.11,
            65.81,
            -109.61,
            65.84,
            -109.6,
            65.7,
            -109.58,
            65.7,
            -109.58,
            65.68,
            -109.7,
            65.67,
            -109.34,
            59.2,
            -109.23,
            59.2,
            -109.23,
            59.18,
            -109.25,
            59.18,
            -109.24,
            59.04,
            -109.74,
            59.01,
            -109.74,
            58.95,
            -110.05,
            58.93,
            -110.01,
            58.31,
            -109.64,
            51.38,
            -109.41,
            47.29,
            -109.35,
            47.29,
            -109.35,
            47.28,
            -109.16,
            47.29,
            -109.16,
            47.23,
            -108.66,
            47.26,
            -108.65,
            47.12,
            -108.63,
            47.12,
            -108.63,
            47.1,
            -107.34,
            47.17,
            -107.17,
            44.13,
            -108.46,
            44.06,
            -108.46,
            44.04,
            -108.48,
            44.04,
            -108.47,
            43.9,
            -108.98,
            43.87,
            -108.97,
            43.81,
            -109.22,
            43.79,
            -109.19,
            43.23,
            -109.11,
            43.23,
            -109.14,
            43.7,
            -108.97,
            43.71,
            -108.94,
            43.24,
            -108.86,
            43.25,
            -108.86,
            43.06,
            -74.63,
            43.06,
            -74.63,
            43.01,
            -74.43,
            43.01,
            -74.43,
            37.13,
            -74.43,
            31.26,
            -74.08,
            31.26,
            -74.08,
            29.03,
            -76.55,
            29.12,
            -76.55,
            29.21,
            -76.65,
            29.21,
            -76.65,
            29.28,
            -77.59,
            29.28,
            -77.59,
            29.13,
            -80.45,
            29.13,
            -80.45,
            29.52,
            -84.72,
            29.52,
            -85.4,
            29.52,
            -85.62,
            15.81,
            -85.68,
            12.17,
            -86.06,
            12.18,
            -86.08,
            10.74,
            -84.85,
            10.72,
            -84.84,
            11.27,
            -84.74,
            11.27,
            -84.74,
            11.41,
            -81.08,
            11.45,
            -81.08,
            11.34,
            -80.53,
            11.34,
            -80.53,
            11.45,
            -76.74,
            11.47,
            -76.74,
            11.35,
            -76.19,
            11.36,
            -76.19,
            11.48,
            -72.43,
            11.5,
            -72.43,
            11.38,
            -71.88,
            11.38,
            -71.88,
            11.5,
            -68.37,
            11.51,
            -68.13,
            11.51,
            -68.13,
            11.39,
            -67.58,
            11.39,
            -67.58,
            11.51,
            -67.33,
            11.51,
            -67.33,
            12.27,
            -64.04,
            12.28,
            -64.04,
            11.52,
            -63.79,
            11.52,
            -63.79,
            11.4,
            -63.24,
            11.41,
            -63.24,
            11.53,
            -59.52,
            11.55,
            -59.52,
            11.43,
            -58.97,
            11.43,
            -58.97,
            11.55,
            -55.69,
            11.55,
            -55.69,
            11.43,
            -55.14,
            11.43,
            -54.14,
            11.43,
            -54.14,
            11.55,
            -51.44,
            11.53,
            -51.44,
            11.4,
            -49.87,
            11.4,
            -49.87,
            11.73,
            -47.13,
            11.73,
            -47.13,
            11.4,
            -45.56,
            11.4,
            -45.55,
            11.73,
            -42.81,
            11.72,
            -42.82,
            11.39,
            -41.25,
            11.39,
            -41.25,
            11.51,
            -38.05,
            11.5,
            -38.05,
            11.38,
            -36.83,
            11.38,
            -32.6,
            11.36,
            -32.6,
            11.49,
            -29.82,
            11.47,
            -29.82,
            11.34,
            -28.29,
            11.33,
            -28.29,
            11.46,
            -25.52,
            11.44,
            -25.52,
            11.31,
            -24,
            11.3,
            -24,
            11.43,
            -21.16,
            11.41,
            -21.16,
            11.28,
            -19.7,
            11.27,
            -19.7,
            11.4,
            -16.93,
            11.38,
            -16.93,
            11.26,
            -16.46,
            11.26,
            -16.46,
            11.41,
            -15.86,
            11.41,
            -15.86,
            11.56,
            -12.16,
            11.56,
            -12.16,
            11.41,
            -11.55,
            11.41,
            -11.54,
            11.56,
            -11.15,
            11.56,
            -7.84,
            11.56,
            -7.95,
            8.69,
            -7.95,
            8.67,
            -8,
            8.67,
            -8.01,
            8.42,
            -7.99,
            8.42,
            -8.02,
            7.99,
            -8.31,
            4.16,
            -8.63,
            4.19,
            -8.69,
            3.3,
            -7.8,
            3.23,
            -7.77,
            3.56,
            -6.42,
            3.47,
            -2.66,
            3.2,
            -2.66,
            3,
            -2.67,
            2.9,
            -1.77,
            2.83,
            -1.71,
            3.73,
            -2.03,
            3.75,
            -1.68,
            7.38,
            -1.71,
            8.62,
            -1.7,
            11.41,
            -2.04,
            28.82,
            -2.07,
            28.82,
            -2.5,
            34.58,
            -2.73,
            34.57,
            -2.88,
            36.56,
            -2.65,
            36.58,
            -3.09,
            42.44,
            -3.48,
            42.46,
            -3.51,
            42.86,
            -3.61,
            43.23,
            -3.84,
            43.55,
            -4.17,
            43.78,
            -4.53,
            43.86,
            -6.79,
            43.86,
            -6.79,
            49.78,
            -5.06,
            49.78,
            -4.61,
            49.91,
            -4.2,
            50.2,
            -3.92,
            50.61,
            -3.8,
            51.07,
            -4.42,
            58.85,
            -4.58,
            59.29,
            -4.89,
            59.68,
            -5.32,
            59.94,
            -5.78,
            60.04,
            -8.1,
            60.04,
            -8.1,
            65.84,
            -6.23,
            65.84,
            -5.8,
            65.95,
            -5.4,
            66.23,
            -5.15,
            66.64,
            -5.07,
            67.09,
            -6.06,
            79.53,
            -6.2,
            79.97,
            -6.49,
            80.36,
            -6.91,
            80.62,
            -7.36,
            80.72,
            -8.02,
            80.72,
            -8.02,
            84.79,
            -6.07,
            84.79,
            -6.07,
            85.22,
            -33.56,
            85.23,
            -51.84,
            85.22,
            -51.84,
            85.33,
            -55.38,
            85.33,
            -55.38,
            85.22,
            -71.65,
            85.22,
            -75.05,
            85.22
         ]
      ],
      "rocade_fond_ss1_phase00": [
         [
            -3.46,
            42.31,
            -6.64,
            85.3,
            -75.03,
            85.22,
            -75.03,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -83.97,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.46,
            43.03,
            -70.89,
            43.03,
            -67.79,
            43.03,
            -67.79,
            43.53,
            -60.09,
            43.53,
            -60.09,
            43.76,
            -34.45,
            43.76,
            -34.45,
            43.66,
            -21.54,
            43.35,
            -21.55,
            42.63,
            -3.46,
            42.31
         ]
      ],
      "rocade_fond_ss1_phase01": [
         [
            -3.46,
            42.31,
            -6.64,
            85.3,
            -75.03,
            85.22,
            -75.03,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -83.97,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.46,
            43.03,
            -70.89,
            43.03,
            -67.79,
            43.03,
            -67.79,
            43.53,
            -60.09,
            43.53,
            -60.09,
            43.76,
            -34.45,
            43.76,
            -34.45,
            43.66,
            -21.54,
            43.35,
            -21.55,
            42.63,
            -3.46,
            42.31
         ]
      ],
      "rocade_fond_ss1_phase02_03": [
         [
            -3.46,
            42.31,
            -6.64,
            85.3,
            -75.03,
            85.22,
            -75.03,
            80.39,
            -110.44,
            80.34,
            -108.47,
            43.33,
            -83.97,
            43.33,
            -84.03,
            43.53,
            -75.38,
            43.53,
            -75.38,
            43.11,
            -74.46,
            43.11,
            -74.46,
            43.03,
            -70.89,
            43.03,
            -67.79,
            43.03,
            -67.79,
            43.53,
            -60.09,
            43.53,
            -60.09,
            43.76,
            -34.45,
            43.76,
            -34.45,
            43.66,
            -21.54,
            43.35,
            -21.55,
            42.63,
            -3.46,
            42.31
         ]
      ],
      "rocade_fond_ss1_phase04_05": [
         [
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -110.28,
            77.21,
            -110.44,
            80.34,
            -104.94,
            80.35,
            -75.03,
            80.39,
            -75.03,
            85.22,
            -74.81,
            85.22,
            -10.51,
            85.22,
            -6.64,
            85.3,
            -5.15,
            66.51,
            -5.35,
            66.5,
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.56,
            43.55,
            -3.46,
            42.31,
            -21.55,
            42.63,
            -21.54,
            43.35,
            -34.45,
            43.66,
            -34.45,
            43.76,
            -60.09,
            43.76,
            -60.09,
            43.53,
            -64.9,
            43.53,
            -64.9,
            40.14,
            -78.12,
            40.14,
            -78.12,
            43.53,
            -81.37,
            43.53,
            -84.03,
            43.53
         ]
      ],
      "rocade_fond_ss1_phase06": [
         [
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -110.28,
            77.21,
            -110.44,
            80.34,
            -104.94,
            80.35,
            -75.03,
            80.39,
            -75.03,
            85.22,
            -74.81,
            85.22,
            -10.51,
            85.22,
            -6.64,
            85.3,
            -5.15,
            66.51,
            -5.35,
            66.5,
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.56,
            43.55,
            -3.46,
            42.31,
            -21.55,
            42.63,
            -21.54,
            43.35,
            -34.45,
            43.66,
            -34.45,
            43.76,
            -60.09,
            43.76,
            -60.09,
            43.53,
            -64.9,
            43.53,
            -64.9,
            40.14,
            -78.12,
            40.14,
            -78.12,
            43.53,
            -81.37,
            43.53,
            -84.03,
            43.53
         ]
      ],
      "rocade_fond_ss2_phase00": [
         [
            -34,
            44,
            -34,
            44,
            -22,
            43,
            -22,
            43,
            -3,
            42,
            -5,
            66,
            -5,
            67,
            -7,
            85,
            -59,
            85,
            -59,
            44,
            -34,
            44
         ],
         [
            -75,
            74,
            -75,
            80,
            -110,
            80,
            -110,
            74,
            -75,
            74
         ]
      ],
      "rocade_fond_ss2_phase01": [
         [
            -34,
            44,
            -34,
            44,
            -22,
            43,
            -22,
            43,
            -3,
            42,
            -5,
            66,
            -5,
            67,
            -7,
            85,
            -59,
            85,
            -59,
            44,
            -34,
            44
         ],
         [
            -75,
            74,
            -75,
            80,
            -110,
            80,
            -110,
            74,
            -75,
            74
         ]
      ],
      "rocade_fond_ss2_phase02_03": [
         [
            -34,
            44,
            -34,
            44,
            -22,
            43,
            -22,
            43,
            -3,
            42,
            -5,
            66,
            -5,
            67,
            -7,
            85,
            -59,
            85,
            -59,
            44,
            -34,
            44
         ],
         [
            -75,
            74,
            -75,
            80,
            -110,
            80,
            -110,
            74,
            -75,
            74
         ]
      ],
      "rocade_fond_ss2_phase04_05": [
         [
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.46,
            42.31,
            -21.55,
            42.63,
            -21.54,
            43.35,
            -34.46,
            43.66,
            -34.46,
            43.76,
            -59.3,
            43.76,
            -59.3,
            44.03,
            -59.28,
            46.08,
            -63.43,
            46.12,
            -67.79,
            46.12,
            -67.79,
            43.03,
            -70.89,
            43.03,
            -74.46,
            43.03,
            -74.46,
            43.11,
            -75.38,
            43.11,
            -75.38,
            43.53,
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -110.09,
            73.62,
            -110.24,
            77.21,
            -110.24,
            80.34,
            -104.94,
            80.34,
            -75.03,
            80.39,
            -75.03,
            85.22,
            -74.9,
            85.22,
            -71.61,
            85.22,
            -71.61,
            84.91,
            -71.61,
            81.55,
            -67.08,
            81.55,
            -59.04,
            81.56,
            -59.04,
            84.34,
            -59.04,
            85.23,
            -6.63,
            85.23,
            -5.15,
            66.51,
            -5.35,
            66.5,
            -4.3,
            52.78
         ]
      ],
      "rocade_fond_ss2_phase06": [
         [
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.46,
            42.31,
            -21.55,
            42.63,
            -21.54,
            43.35,
            -34.46,
            43.66,
            -34.46,
            43.76,
            -59.3,
            43.76,
            -59.3,
            44.03,
            -59.28,
            46.08,
            -63.43,
            46.12,
            -67.79,
            46.12,
            -67.79,
            43.03,
            -70.89,
            43.03,
            -74.46,
            43.03,
            -74.46,
            43.11,
            -75.38,
            43.11,
            -75.38,
            43.53,
            -84.03,
            43.53,
            -84.03,
            43.33,
            -108.47,
            43.33,
            -110.09,
            73.62,
            -110.24,
            77.21,
            -110.24,
            80.34,
            -104.94,
            80.34,
            -75.03,
            80.39,
            -75.03,
            85.22,
            -74.9,
            85.22,
            -71.61,
            85.22,
            -71.61,
            84.91,
            -71.61,
            81.55,
            -67.08,
            81.55,
            -59.04,
            81.56,
            -59.04,
            84.34,
            -59.04,
            85.23,
            -6.63,
            85.23,
            -5.15,
            66.51,
            -5.35,
            66.5,
            -4.3,
            52.78
         ]
      ],
      "rocade_fond_ss3_phase00": [
         [
            -31.99,
            85.22,
            -31.99,
            84.64,
            -6.72,
            85.21,
            -5.15,
            66.51,
            -5.29,
            66.51,
            -5.35,
            66.51,
            -3.54,
            43.28,
            -21.57,
            43.59,
            -21.57,
            43.35,
            -38.57,
            43.76,
            -59.3,
            43.76,
            -59.04,
            85.22,
            -31.99,
            85.22
         ]
      ],
      "rocade_fond_ss3_phase01": [
         [
            -31.99,
            85.22,
            -31.99,
            84.64,
            -6.72,
            85.21,
            -5.15,
            66.51,
            -5.29,
            66.51,
            -5.35,
            66.51,
            -3.54,
            43.28,
            -21.57,
            43.59,
            -21.57,
            43.35,
            -38.57,
            43.76,
            -59.3,
            43.76,
            -59.04,
            85.22,
            -31.99,
            85.22
         ]
      ],
      "rocade_fond_ss3_phase02_03": [
         [
            -31.99,
            85.22,
            -31.99,
            84.64,
            -6.72,
            85.21,
            -5.15,
            66.51,
            -5.29,
            66.51,
            -5.35,
            66.51,
            -3.54,
            43.28,
            -21.57,
            43.59,
            -21.57,
            43.35,
            -38.57,
            43.76,
            -59.3,
            43.76,
            -59.04,
            85.22,
            -31.99,
            85.22
         ]
      ],
      "rocade_fond_ss3_phase04_05": [
         [
            -21.57,
            43.35,
            -38.57,
            43.76,
            -59.3,
            43.76,
            -59.04,
            84.33,
            -59.04,
            85.22,
            -31.99,
            85.22,
            -31.99,
            84.64,
            -30.77,
            84.64,
            -6.72,
            85.21,
            -5.15,
            66.51,
            -5.35,
            66.51,
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.54,
            43.28,
            -14.25,
            43.49,
            -21.57,
            43.59,
            -21.57,
            43.35
         ]
      ],
      "rocade_fond_ss3_phase06": [
         [
            -21.57,
            43.35,
            -38.57,
            43.76,
            -59.3,
            43.76,
            -59.04,
            84.33,
            -59.04,
            85.22,
            -31.99,
            85.22,
            -31.99,
            84.64,
            -30.77,
            84.64,
            -6.72,
            85.21,
            -5.15,
            66.51,
            -5.35,
            66.51,
            -4.3,
            52.78,
            -4.26,
            52.78,
            -3.54,
            43.28,
            -14.25,
            43.49,
            -21.57,
            43.59,
            -21.57,
            43.35
         ]
      ]
   };
   return slabCoordinates[name]
}

/**
 *
 * @returns An array of objects {startDate: string, endDate:string } reprensenting all different existing data ranges (no doublon)
 */
async function getAllDifferentDateRanges() {
   const dates = [];
   const finalDateRanges = [];

   //Gather data in rocade file and areas file
   const [dateRangesFromRocades, dateRangesFromGeneral] = await Promise.all([
      getAllRocadesWithRangeDates(),
      getGeneralClientsDataFromFile(),
   ]);

   //Retrieve dates ranges from rocade file
   if (range.startDate && range.endDate) {
      for (const range of dateRangesFromRocades) {
         if (!dates.includes(range.startDate.getTime())) {
            dates.push(range.startDate.getTime());
         }
         if (!dates.includes(range.endDate.getTime())) {
            dates.push(range.endDate.getTime());
         }
      }
   }

   //retrieve dates from areas file
   for (const range of dateRangesFromGeneral) {
      if (range.dates && range.dates.length >= 17) {
         const areaRange = DateUtils.splitDateRange(range.dates, "_");
         const areaStart = DateUtils.generateDateFromString(areaRange[0]);
         const areaEnd = DateUtils.generateDateFromString(areaRange[1]);
         if (areaStart && areaEnd) {
            if (!dates.includes(areaStart.getTime())) {
               dates.push(areaStart.getTime());
            }
            if (!dates.includes(areaEnd.getTime())) {
               dates.push(areaEnd.getTime());
            }
         }
      }
   }

   //Sort dates
   dates.sort((a, b) => {
      return a - b;
   });
   for (let index = 0; index < dates.length; index++) {
      if (dates[index + 1] - dates[index] <= 3600 * 1000 * 24) {
         dates.splice(index + 1, 1);
      }
   }

   //create ranges. Each ranges begins one day after the last range, except for the first one, so we add one day.
   for (let index = 0; index < dates.length; index++) {
      if (index < dates.length - 1) {
         finalDateRanges.push({
            startDate:
               index > 0
                  ? DateUtils.addOneDay(new Date(dates[index]))
                  : new Date(dates[index]),
            endDate: new Date(dates[index + 1]),
         });
      }
   }

   return finalDateRanges;
}

/**
 * @returns dates of phases
 */
export async function getPhaseDatesFromFile() {
   const result = await axios.get(
      "/xls/filtres_phases.xlsx",
      { responseType: "arraybuffer" }
   );

   console.log("PUBLIC URL:", process.env.PUBLIC_URL)
   const data = new Uint8Array(result.data);
   const wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

/**
 * @returns dates of phases minmimal
 */
export async function getReferencesPhaseDatesFromFile() {
   const result = await axios.get(
      "/xls/filtres_phases_minimal.xlsx",
      { responseType: "arraybuffer" }
   );
   const data = new Uint8Array(result.data);
   const wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

/**
 * @returns data from general_information xlsx file.
 */
async function getGeneralClientsDataFromFile() {
   const result = await axios.get(
      "/xls/rocade_nomenclature.xlsx",
      { responseType: "arraybuffer" }
   );
   const data = new Uint8Array(result.data);
   const wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

/**
 * @returns data from general_information xlsx file.
 */
async function getGeneralClientsDataSimpleFromFile() {
   const result = await axios.get(
      "/xls/rocade_nomenclature_simple.xlsx",
      { responseType: "arraybuffer" }
   );
   const data = new Uint8Array(result.data);
   const wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

/**
 * @returns data from general_information xlsx file.
 */
export async function readColorFile() {
   const result = await axios.get(
      "/xls/rocade_code_couleur.xlsx",
      { responseType: "arraybuffer" }
   );
   const data = new Uint8Array(result.data);
   const wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

/**
 * Iterates through ifc file and retrieve all areas.
 * @returns all areas in ifc file
 */
async function getAllAreaInIFCFile() {
   const ifcLoader = new IFCLoader();
   ifcLoader.ifcManager.setWasmPath("/wasm", true);
   const allAreasInFile1 = await fetch(
      "/ifc/IFC_Phase_0.ifc"
   )
      .then((response) => response.text())
      .then((fileAsText) =>
         LoadFileData(fileAsText).then((allAreas) => allAreas)
      );
      console.log("IFC 0:", allAreasInFile1.length);
   const allAreasInFile2 = await fetch(
      "/ifc/IFC_Phase_6.ifc"
   )
      .then((response) => response.text())
      .then((fileAsText) =>
         LoadFileData(fileAsText).then((allAreas) => allAreas)
      );
      console.log("IFC 6:", allAreasInFile2.length);

   const allAreasInFile3 = await fetch(
      "/ifc/IFC_Phase_Travaux.ifc"
   )
      .then((response) => response.text())
      .then((fileAsText) =>
         LoadFileData(fileAsText).then((allAreas) => allAreas)
      );
      console.log("IFC Travaux:", allAreasInFile3.length);

   const allAreasInFile = allAreasInFile1.concat(allAreasInFile2).concat(allAreasInFile3);
   return allAreasInFile;
}

export async function getAllRocadesWithRangeDates() {
   const result = await axios.get(
      "/xls/dates_fond_rocade.xlsx",
      {
         responseType: "arraybuffer",
      }
   );

   let data = new Uint8Array(result.data);
   let wb = read(data, { type: "array", cellDates: true });
   const dataFromSheet = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
   return dataFromSheet;
}

export async function getRocadeNamesByDateRange(startDate, endDate) {
   let rocadeNames = [];
   const start = DateUtils.generateDateFromString(startDate);
   const end = DateUtils.generateDateFromString(endDate);
   const rocades = await Promise.resolve(getAllRocadesWithRangeDates());
   for (const rocade of rocades) {
      if (start <= rocade.endDate && rocade.startDate <= end) {
         rocadeNames.push(rocade.rocadeName);
      }
   }

   return rocadeNames;
}

export async function getRocadeNameByFloorAndDateRange(
   floor,
   startDate,
   endDate
) {
   let rocadeNames = [];
   let rocade = "";
   const start = DateUtils.generateDateFromString(startDate);
   const end = DateUtils.generateDateFromString(endDate);
   const rocades = await Promise.resolve(getAllRocadesWithRangeDates());
   for (const rocade of rocades) {
      if (start <= rocade.endDate && rocade.startDate <= end) {
         rocadeNames.push(rocade.rocadeName);
      }
   }
   for (const currentRocade of rocadeNames) {
      if (currentRocade.includes(floor)) {
         rocade = currentRocade;
         break;
      }
   }
   return rocade;
}

/**
 * Return all clients found in IFCFile (no duplicates)
 */
export async function getAllClientsFromIFCFile() {
   let mainClients = [];
   const ifcData = await Promise.resolve(getGeneralClientsDataFromFile());
   const clients = [];
   for (const area of ifcData) {
      if (!mainClients.includes(area.clientName.toUpperCase())) {
         mainClients.push(area.clientName.toUpperCase());
      }
      if (
         !clients.some(
            (client) =>
               client.clientName === area.clientName &&
               client.zoneName === area.zoneName
         )
      ) {
         clients.push({
            clientId: area.clientId,
            clientName: area.clientName,
            zoneName: area.zoneName,
            leaseArea: area.leaseArea,
            roomName: area.roomName,
            color: undefined,
            clientChecked: true,
         });
      }
   }

   mainClients.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
   const mapClientsColors = await Color.generateAllClientsColors();
   for (const currentClient of clients) {
      let clientName = currentClient.clientName.toUpperCase();
      let mappedColor = mapClientsColors.get(clientName);
      currentClient.color = "#" + mappedColor;
   }

   return clients;
}

/**
 *
 * @param {*} data : client linked to a 'hachure'
 * @returns : an array of objects grouped by floor :
 * floorName :  - clientId,
 *              - clientName,
 *              - area,
 *              - floor,
 *              - startDate-enDate of rent period,
 *              - zoneName
 */
async function groupClientsByFloor(data) {
   let mainClients = [];
   let [clients, vertices] = await Promise.all([data, getAllAreaInIFCFile()]);
   const floors = clients.reduce((floors, item) => {
      let verticeData = [];
      for (let vertice of vertices) {
         if (vertice.clientId === item.clientId) {
            verticeData = vertice.vertices;
            verticeData.push(verticeData[0]);
            const floorName = item.floor.toLowerCase();
            const floor = floors[floorName] || [];

            if (!mainClients.includes(item.clientName.toUpperCase())) {
               mainClients.push(item.clientName.toUpperCase());
            }
            
            floor.push({
               clientId: item.clientId,
               clientName: item.clientName.toUpperCase(),
               area: item.area,
               leaseArea: item.leaseArea,
               roomName: item.roomName,
               floor: item.floor.toLowerCase(),
               startDate: DateUtils.splitDateRange(item.dates, "_")[0],
               endDate: DateUtils.splitDateRange(item.dates, "_")[1],
               clientUnderConstruction: item.length > 0 ? true : false,
               // ownerUnderConstruction: item.ownerUnderConstruction.length > 0 ? true : false,
               zoneName: item.zoneName
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""),
               color: undefined,
               vertices: verticeData,
               verticesOrigin: vertice.spaceOrigin,
               axis: vertice.axis,
               orientation: vertice.orientation,
            });
            floors[floorName] = floor;
         }
      }
      // console.log("FLOORS:", floors);

      return floors;
   }, {});

   mainClients.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
   const mapClientsColors = await Color.generateAllClientsColors();
   for (const [currentFloorKey, currentFloorValues] of Object.entries(floors)) {
      let floorAreas = [...currentFloorValues];
      for (const currentClient of floorAreas) {
         let clientName = currentClient.clientName.toUpperCase();
         let mappedColor = mapClientsColors.get(clientName);
         currentClient.color = "#" + mappedColor;
      }
   }
   return floors;
}

function getMainClients(allLocalClients) {
   let mainClients = [];
   for (const client of allLocalClients) {
      if (!mainClients.includes(client.clientName.toLowerCase())) {
         mainClients.push(client.clientName.toLowerCase());
      }
   }
   return mainClients;
}