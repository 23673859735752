import { getDateRanges } from "@/scripts/xlsx-parser.js"
import { store } from "@/libs/store/store.js";

export class DateUtils {
    static formatDate = (date: Date): string => {
        const local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    };

    static checkDateEquality = (date1: Date, date2: Date): boolean => {
        return date1.getTime() === date2.getTime();
    };

    static generateDateFromString = (date: string): Date => {
        if (date && date.length >= 8) {
            const day = parseInt(date.substring(0, 2));
            const month = parseInt(date.substring(3, 5));
            const year = parseInt(date.substring(6, 10));

            return new Date(Date.UTC(year, month - 1, day));
        }
        return undefined;
    };

    /**
     * Given a date: Date, this function returns it within this format : dd.mm.yyyy
     */
    static generateStringFromDate = (date: Date): string => {
        let stringDate = "";
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        stringDate = (day < 10 ? "0" : "") + day + "." + (month < 10 ? "0" : "") + month + "." + year;
        return stringDate;
    };

    /**
     * 
     * @param dateRange range of date, f.e. : 01.02.22_05.03.22 (17 characters)
     * @param separator the element that split the 2 dates
     * @returns an array with startDate and endDate
     */
    static splitDateRange = (dateRange: string, separator: string) => {
        let splittedDates = dateRange.split(separator);
        splittedDates[0] = this.checkYearFormat(splittedDates[0]);
        splittedDates[1] = this.checkYearFormat(splittedDates[1]);
        return splittedDates;
    };

    static checkYearFormat = (date: string) => {
        if (date && date.length >= 8) {
            let [day, month, year] = date.split(".");
            if (year.length < 4) {
                year = "20" + year;
            }

            return day + "." + month + "." + year;
        }
    };

    static addOneDay = (date: Date) => {
        return new Date(date.getTime() + (3600 * 1000 * 24));
    };

    static async getPhases() {
        let internalPhases = await getDateRanges();
        return internalPhases;
    }

    static setDateTimeToZero = (date: Date): Date => {
        if (date) {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
        }
        return date;
    }

    static isDateInRange = (contour): boolean => {
        const startDate = DateUtils.generateDateFromString(contour.startDate);
        const endDate = DateUtils.generateDateFromString(contour.endDate);
        const date = store.getters.getSelectedFilterDate;
        return startDate <= date && date <= endDate;
    };
}