import mitt from 'mitt'
import { DxfParser } from 'dxf-parser';

export const TITLE_PAGE_INDEX = "Globus Genève | Swiss Prime Site Immobilien";
export const TITLE_PDF_EXPORT = "grand-passage-geneve";

export const EVENT_EMITTER = mitt()
export const DXF_PARSER = new DxfParser();
export const IMAGE_FLOOR_SIZE = { w: 2085, h: 1474 };
export const ARCHICAD_PERIMETER = { w: 147, h: 104 };

export enum STEP_NAME {
    ZERO = "Etat existant",
    LAST = "Etat final"
}

export const FLOOR_NAME_DEPTH_MAPPING = {
    'et7': 8,
    'et6': 7,
    'et5': 6,
    'et4': 5,
    'et3': 4,
    'et2': 3,
    'et1': 2,
    'en1': 1,
    'rdc': 0,
    'es1': -1,
    'ss1': -2,
    'ss2': -3,
    'ss3': -4,
}

export const FLOOR_NAME_BY_KEY = {
    'et7': 'Etage 7',
    'et6': 'Etage 6',
    'et5': 'Etage 5',
    'et4': 'Etage 4',
    'et3': 'Etage 3',
    'et2': 'Etage 2',
    'et1': 'Etage 1',
    'en1': 'Entresol + 1',
    'rdc': 'Rez-de-chaussée',
    'es1': 'Sous-sol Entresol',
    'ss1': 'Sous-sol -1',
    'ss2': 'Sous-sol -2',
    'ss3': 'Sous-sol -3',
}


export const FLOOR_FULLNAME_DEPTH_MAPPING = {
    'Etage 7': 8,
    'Etage 6': 7,
    'Etage 5': 6,
    'Etage 4': 5,
    'Etage 3': 4,
    'Etage 2': 3,
    'Etage 1': 2,
    'Entresol + 1': 1,
    'Rez-de-chaussée': 0,
    'Sous-sol Entresol': -1,
    'Sous-sol -1': -2,
    'Sous-sol -2': -3,
    'Sous-sol -3': -4,
};

export const FloorAbbreviationByValue = (value) => {
    return Object.keys(FLOOR_NAME_DEPTH_MAPPING).find(key => FLOOR_NAME_DEPTH_MAPPING[key] === value);
};

export function getFloorKeyByName(floorName: string): string {
    const floorNumber = FLOOR_FULLNAME_DEPTH_MAPPING[floorName];
    return Object.keys(FLOOR_NAME_DEPTH_MAPPING).find(key => FLOOR_NAME_DEPTH_MAPPING[key] === floorNumber);
}

export function getFloorKeyByAbbreviation(floorAbbr: string): string {
    const floorNumber = FLOOR_NAME_DEPTH_MAPPING[floorAbbr];
    return Object.keys(FLOOR_NAME_DEPTH_MAPPING).find(key => FLOOR_NAME_DEPTH_MAPPING[key] === floorNumber);
}