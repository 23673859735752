/* eslint-disable */
// https://www.npmjs.com/package/threejs-object-controls
import { Color } from '@/utils/colorUtils';
import { ObjectControls } from 'threejs-object-controls';
import * as THREE from 'three';
import { IPlanPicture } from '@/interfaces/IPlanPicture';
import { PhasesService } from './phases.service';
import { FLOOR_NAME_DEPTH_MAPPING } from './constants/constants';
import { store } from "@/libs/store/store.js";
import { ClientsService } from './clients.service';
import { DateUtils } from '../utils/dateUtils';

export class CanvasService {

    static ZOOM_SPEED = 3;
    static ROTATION_SPEED = 0.12;
    static CONTROL_DISTANCE_MIN = 1;
    static CONTROL_DISTANCE_MAX = 600;

    static container;

    static setInitalRotation = (deltaX, deltaY, pivot) => {
        pivot.rotation.x = deltaX * Math.PI / 180;
        pivot.rotation.y = deltaX * Math.PI / 180;
        pivot.rotation.z = deltaY * Math.PI / 180;
        return pivot;
    };

    static createNewObjectControls = (camera, domElement, object3D) => {
        let controls = new ObjectControls(camera, domElement, object3D);
        controls.setDistance(CanvasService.CONTROL_DISTANCE_MIN, CanvasService.CONTROL_DISTANCE_MAX);
        controls.setZoomSpeed(CanvasService.ZOOM_SPEED);
        controls.enableVerticalRotation();
        controls.enableHorizontalRotation();
        controls.setRotationSpeed(CanvasService.ROTATION_SPEED);

        return controls;
    };

    static updateObjectControls = (controls) => {
        let controlsTemp = controls;
        controlsTemp.enableVerticalRotation();
        controlsTemp.enableHorizontalRotation();
        controlsTemp.setDistance(CanvasService.CONTROL_DISTANCE_MIN, CanvasService.CONTROL_DISTANCE_MAX);
        controlsTemp.setZoomSpeed(CanvasService.ZOOM_SPEED);
        controlsTemp.setRotationSpeed(CanvasService.ROTATION_SPEED);
        return controlsTemp;
    };

    static disableObjectControls = (controls) => {
        let controlsTemp = controls;
        controlsTemp.disableVerticalRotation();
        controlsTemp.disableHorizontalRotation();
        return controlsTemp;
    };

    /**
 * Handles mouse wheel interactions detected by Vue JS
 * @event WheelEvent composed of several useful informations (dimension, deltas, movement, offset, ...)
 * @source https://forum.vuejs.org/t/more-convenient-way-of-doing-wheel-events/66708/3
 * @source https://codepen.io/hichem147/pen/dExxNK
 */
    static mouseWheelUpDown = (renderer, scene, camera) => {
        let tempRenderer = renderer;
        tempRenderer.render(scene, camera);
        return tempRenderer;
    }

    /**
     * Preload all floor plans.
     * @returns every floor plans with mask
     */
    static preloadAllFloorPlans = async (): Promise<Array<IPlanPicture>> => {
        let floorPlans = [];
        for (const rocade of PhasesService.getAllRocadeNames()) {
            if (Object.keys(FLOOR_NAME_DEPTH_MAPPING).some(k => rocade.includes(k))) {
                const TEXTURE_LOADER = new THREE.TextureLoader();
                const textureFondPlan = await TEXTURE_LOADER.load("/img/fondplan/" + rocade + ".jpg");
                const textureAlpha = await TEXTURE_LOADER.load("/img/mask/" + rocade + ".jpg");
                floorPlans.push({ rocade: rocade, img: textureFondPlan, mask: textureAlpha });
            }
        }
        return floorPlans;
    }

    static getActiveFloorsData = () => {
        // This variable is used to retrieve filtered areas (also sorted by floor)
        let filteredClientsByFloor = {};
        const floorVisibility = store.getters.floorVisibility; //selected floors
        const activeFloors = floorVisibility.filter(floor => floor.checked).map(floor => floor.nb);
        const activeFloorKeys = [];
        Object.keys(FLOOR_NAME_DEPTH_MAPPING).forEach(floorKey => {
            if(activeFloors.includes(FLOOR_NAME_DEPTH_MAPPING[floorKey])) {
                 activeFloorKeys.push(floorKey);
            }
        });

        activeFloorKeys.forEach(activeFloorKey => filteredClientsByFloor[activeFloorKey] = store.getters.getGeneralData[activeFloorKey].filter(client => ClientsService.isClientVisible(client) && DateUtils.isDateInRange(client)));
        return filteredClientsByFloor;
    }
}