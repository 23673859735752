import { DateUtils } from "@/utils/dateUtils";
import { store } from "@/libs/store/store.js";
import { getFloorKeyByAbbreviation } from "./constants/constants";

export class PhasesService {
    static async matchPhase(dateInTime: number) {
        const date = new Date(dateInTime * 1);
        const phases = await DateUtils.getPhases();
        for (const p of phases) {
          if (p.startDate <= date && date <= p.endDate) {
            return p;
          }
        }
        throw new Error("PhaseService > matchPhage() : This date doesn't match any phase.");
      }

      static getRocadeName = (floorName, phase) => {
        let rocadeName = "";
        const potentialRocadeNames = [];
        for (const rocade of store.getters.getRocades) {
          if (
            phase.startDate <= rocade.endDate &&
            rocade.startDate <= phase.endDate
          ) {
            potentialRocadeNames.push(rocade);
          }
        }
  
        const floorKey = getFloorKeyByAbbreviation(floorName);
        for (const currentRocade of potentialRocadeNames) {
          if (currentRocade.rocadeName.includes(floorKey)) {
            rocadeName = currentRocade.rocadeName;
            break;
          }
        }
        return rocadeName;
      }

      static getAllRocadeNames = (): Array<string> => {
        return [...new Set<string>(store.getters.getRocades.map(rocade => rocade.rocadeName))];
      };
}